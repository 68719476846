// interfaces/types
import type { FeatureToggles } from './types/basicToggleTypes';
import type { Headers } from './types/basicRequestTypes';

// utils
import { getToggleOverrides, ToggleOverridesResult } from './toggleOverrideHelper';

export type QueryParamsWithToggleOverrides = {
    enableToggles?: string;
    disableToggles?: string;
};

export const getToggleOverridesFromQueryParams = (params: QueryParamsWithToggleOverrides): ToggleOverridesResult =>
    getToggleOverrides({
        enableToggles: params.enableToggles,
        disableToggles: params.disableToggles
    });

export const mergeFeatureToggles = (...toggleLists: FeatureToggles[]): FeatureToggles => {
    if (toggleLists.length < 2) {
        throw new Error('At least 2 lists must be provided to mergeFeatureToggles');
    }
    return toggleLists.reduce((previousValue: FeatureToggles, currentValue: FeatureToggles) => {
        return { ...previousValue, ...currentValue };
    });
};

export const getToggleOverridesFromHeaders = (headers: Headers, headerName: string): FeatureToggles => {
    if (!headerName) {
        throw new Error('Header name must be provided to getToggleOverridesFromHeaders');
    }
    if (!headers) {
        return {};
    }
    const headerValue = headers[headerName];
    if (!headerValue) {
        return {};
    }
    return JSON.parse(headerValue);
};
