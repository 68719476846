export enum TimeDiffStatus {
    Normal,
    Uninitialized
}

export type TimeDiffResult = {
    seconds: number;
    date1: Date;
    date2: Date;
    status: TimeDiffStatus;
};

export const calcTimeDiff = (date1: Date, date2: Date): TimeDiffResult => {
    if (!date1) {
        return {
            seconds: null,
            date1,
            date2,
            status: TimeDiffStatus.Uninitialized
        };
    }
    const currentUpdateDate = new Date();
    const nowTime = currentUpdateDate.getTime();
    const previousTime = date1.getTime();
    const timeDiff = nowTime - previousTime;
    return {
        seconds: timeDiff / 1000,
        date1,
        date2,
        status: TimeDiffStatus.Normal
    };
};
