// externals

import uniq from 'lodash.uniq';
import isEqual from 'lodash.isequal';

type ObjectToCompare = Record<string, any> | undefined;

/**
 *
 * @params two objects
 * @returns a list of keys that have different values between the two objects
 */

export const getDiffPropsFromObjects = (oldData: ObjectToCompare, newData: ObjectToCompare): string[] => {
    if (!oldData || !newData) return [];
    const data = uniq([...Object.keys(oldData), ...Object.keys(newData)]);
    const keys = [];
    for (const key of data) {
        if (!isEqual(oldData[key], newData[key])) {
            keys.push(key);
        }
    }

    return keys;
};
