// libraries
import { axiosRestApiAdapter, restApiCaller, Headers } from '@makemydeal/dr-common-utils';

// interfaces/types
import type { ToggleDataResult, ToggleDataResultDataItem } from './types/toggleApiResultTypes';

// utils
import { ApiCallStatus, determineStatusAndFetchIfStale, EnsureDataCurrentResult } from './utils/ensureDataCurrentUtils';
import { isToggleEnabled } from './utils/toggleCalcUtils';
import { buildToggleDataLocalCache } from './utils/toggleCache';
import { buildTogglesClientCacheKey, ToggleCacheItems } from './utils/togglesClientCache';
import { TOGGLE_CACHE_TIMEOUT } from './constants/defaults';

export type IsToggleEnabledResult = boolean;

export type AreTogglesEnabledResultItem = {
    toggle: string;
    enabled: boolean;
};

export type AreTogglesEnabledResult = AreTogglesEnabledResultItem[];

export type FetchAllTogglesResultItem = {
    enabled: boolean;
    lastUsed?: string;
    name: string;
    source: string;
};

export type FetchAllTogglesResult = {
    toggles: FetchAllTogglesResultItem[];
    isCachedData: boolean;
};

export type FetchRawToggleDataResult = {
    featureToggleData: ToggleDataResultDataItem[];
    isCachedData: boolean;
};

export type ToggleDataOptions = {
    includeStats: boolean;
};

export const DEFAULT_TOGGLE_DATA_OPTIONS: ToggleDataOptions = {
    includeStats: false
};

/**
 * TogglesClient can be used in two ways:
 * 1) call setUrl (not init) but each toggle call is asynchronous.
 * 2) call init asynchronously instead of setUrl, after that use isCachedToggleEnabled to retrieve a cached toggle synchronously.
 *
 * The first apporach may be more convenient when initialization can't easily be done asynchronously.
 * The second approach may be more convenient when initialization can be done asynchronously (so the toggles are cached on
 *   start-up).
 */
export class TogglesClient {
    initUsed: boolean;
    initCompleted: boolean;
    includeStats: boolean;
    toggleDataEndpoint: string;
    timeoutHandle: any;
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    constructor(axios: any, public callSource: string, toggleDataOptions: ToggleDataOptions = DEFAULT_TOGGLE_DATA_OPTIONS) {
        this.includeStats = toggleDataOptions.includeStats;
        this.cacheItems = new ToggleCacheItems();
        const apiCaller = axiosRestApiAdapter.buildApiCallerForAxios(axios);
        restApiCaller.setupApiCaller(apiCaller);
    }
    isConfigured = (): boolean => {
        return !!this.toggleDataEndpoint;
    };
    init = async (url: string): Promise<void> => {
        this.initUsed = true;
        this.setUrl(url);
        await this.ensureDataCurrent();
        this.initCompleted = true;
        this.setupRecurringCacheRefresh();
    };
    done = () => {
        clearTimeout(this.timeoutHandle);
    };
    setupRecurringCacheRefresh = () => {
        this.timeoutHandle = setTimeout(() => {
            // istanbul ignore next
            if (this?.forceRefreshCachedDataLazily) {
                this.forceRefreshCachedDataLazily();
            }
            // istanbul ignore next
            if (this?.setupRecurringCacheRefresh) {
                this.setupRecurringCacheRefresh();
            }
        }, TOGGLE_CACHE_TIMEOUT);
    };
    setUrl = (url: string): void => {
        if (this.toggleDataEndpoint && url !== this.toggleDataEndpoint) {
            // this invalidates any cached items because it could be a different environment URL
            this.cacheItems = new ToggleCacheItems();
        }
        this.toggleDataEndpoint = url;
    };
    isCacheDataAvailable = (): boolean => {
        const cacheKey = buildTogglesClientCacheKey();
        const dateLastUpdated = this.cacheItems.getCachedDateLastUpdated(cacheKey);
        return !!dateLastUpdated;
    };
    isCachedToggleEnabled = (toggleName: string, dealerId?: string, userId?: string): IsToggleEnabledResult => {
        if (!this.initUsed) {
            throw new Error('init must be called before isCachedToggleEnabled');
        }
        if (!this.initCompleted) {
            throw new Error('asynchronous init must complete before calling isCachedToggleEnabled');
        }
        if (!this.isCacheDataAvailable()) {
            throw new Error('isCachedToggleEnabled must not be called before init async call has completed.');
        }
        const cacheKey = buildTogglesClientCacheKey();
        const featureToggleDataByName = this.cacheItems.getCachedFeatureToggleByNameData(cacheKey);
        const toggleEnabled = isToggleEnabled(featureToggleDataByName, toggleName, dealerId, userId);
        return toggleEnabled;
    };
    isToggleEnabled = async (toggleName: string, dealerId?: string, userId?: string): Promise<IsToggleEnabledResult> => {
        if (this.initUsed) {
            throw new Error('You may not use isToggleEnabled with init - use isCachedToggleEnabled instead');
        }
        await this.ensureDataCurrent();
        const cacheKey = buildTogglesClientCacheKey();
        const featureToggleDataByName = this.cacheItems.getCachedFeatureToggleByNameData(cacheKey);
        return isToggleEnabled(featureToggleDataByName, toggleName, dealerId, userId);
    };
    areTogglesEnabled = async (toggleNames: string[], dealerId?: string, userId?: string): Promise<AreTogglesEnabledResult> => {
        if (this.initUsed) {
            throw new Error('You may not use areTogglesEnabled with init - use isCachedToggleEnabled instead');
        }
        await this.ensureDataCurrent();
        const cacheKey = buildTogglesClientCacheKey();
        const featureToggleDataByName = this.cacheItems.getCachedFeatureToggleByNameData(cacheKey);
        const toggles = toggleNames.map((toggleName) => ({
            toggle: toggleName,
            enabled: isToggleEnabled(featureToggleDataByName, toggleName, dealerId, userId)
        }));
        return toggles;
    };
    fetchAllToggles = async (dealerId?: string, userId?: string): Promise<FetchAllTogglesResult> => {
        if (this.initUsed) {
            throw new Error('You may not use fetchAllToggles with init - use isCachedToggleEnabled instead');
        }
        const ensureDataCurrentResult = await this.ensureDataCurrent();
        const cacheKey = buildTogglesClientCacheKey();
        const featureToggleDataByName = this.cacheItems.getCachedFeatureToggleByNameData(cacheKey);
        const toggles = Object.keys(featureToggleDataByName).map((name) => {
            const featureToggleData = featureToggleDataByName[name];
            const toggleResultItem: FetchAllTogglesResultItem = {
                name,
                source: featureToggleData.source,
                enabled: isToggleEnabled(featureToggleDataByName, name, dealerId, userId)
            };
            if (featureToggleData.lastUsed) {
                toggleResultItem.lastUsed = featureToggleData.lastUsed;
            }
            return toggleResultItem;
        });
        return {
            toggles,
            isCachedData: ensureDataCurrentResult.isCachedData
        };
    };
    fetchRawToggleData = async (): Promise<FetchRawToggleDataResult> => {
        if (this.initUsed) {
            throw new Error('You may not use fetchRawToggleData with init - use isCachedToggleEnabled instead');
        }
        const ensureDataCurrentResult = await this.ensureDataCurrent();
        const cacheKey = buildTogglesClientCacheKey();
        const featureToggleData = this.cacheItems.getCachedFeatureToggleData(cacheKey);
        return {
            featureToggleData,
            isCachedData: ensureDataCurrentResult.isCachedData
        };
    };
    private apiCallStatus: ApiCallStatus = ApiCallStatus.Waiting;
    private cacheItems: ToggleCacheItems;
    private ensureDataCurrent = async (): Promise<EnsureDataCurrentResult> => {
        const cacheKey = buildTogglesClientCacheKey();
        const dateLastUpdated = this.cacheItems.getCachedDateLastUpdated(cacheKey);
        const featureToggleData = this.cacheItems.getCachedFeatureToggleData(cacheKey);
        const result = await determineStatusAndFetchIfStale(
            dateLastUpdated,
            this.apiCallStatus,
            featureToggleData,
            async () => {
                this.apiCallStatus = ApiCallStatus.Busy;
                try {
                    const featureToggleData = await this.fetchToggleData();
                    this.cacheItems.setCachedFeatureToggleData(cacheKey, featureToggleData);
                    const featureToggleDataByName = buildToggleDataLocalCache(featureToggleData);
                    this.cacheItems.setCachedFeatureToggleByNameData(cacheKey, featureToggleDataByName);
                    this.apiCallStatus = ApiCallStatus.Waiting;
                } catch (err) {
                    this.apiCallStatus = ApiCallStatus.Waiting;
                    throw err;
                }
            },
            () => ({
                apiCallStatus: this.apiCallStatus,
                dateLastUpdated: this.cacheItems.getCachedDateLastUpdated(cacheKey)
            })
        );
        this.cacheItems.setCachedDateLastUpdated(cacheKey, result.dateLastUpdated);
        return result;
    };
    private forceRefreshCachedDataLazily = (): Promise<void> => {
        const cacheKey = buildTogglesClientCacheKey();
        const newDateLastUpdated = new Date();
        return this.fetchToggleData()
            .then((featureToggleData) => {
                this.cacheItems.setCachedFeatureToggleData(cacheKey, featureToggleData);
                const featureToggleDataByName = buildToggleDataLocalCache(featureToggleData);
                this.cacheItems.setCachedFeatureToggleByNameData(cacheKey, featureToggleDataByName);
                this.cacheItems.setCachedDateLastUpdated(cacheKey, newDateLastUpdated);
            })
            .catch(
                // istanbul ignore next
                (err) => {
                    // istanbul ignore next
                    throw err;
                }
            );
    };
    private fetchToggleData = async (): Promise<ToggleDataResultDataItem[]> => {
        if (!this.toggleDataEndpoint) {
            throw new Error('Unable to fetch toggle data if toggle data endpoint has not be provided- you must call setUrl first!');
        }
        const headers: Headers = {
            Accept: 'application/json',
            'x-dr-source': this.callSource
        };
        if (this.includeStats) {
            headers['x-dr-options'] = 'include-stats';
        }
        const togglesDataEndpointResult = await restApiCaller.getResource(this.toggleDataEndpoint, { headers });
        if (!togglesDataEndpointResult.ok) {
            throw new Error(
                `Unable to fetch toggle data using URL ${this.toggleDataEndpoint}: ` + `"${togglesDataEndpointResult.message}"`
            );
        }
        const responseTyped = togglesDataEndpointResult.response as ToggleDataResult;
        return responseTyped.data.items;
    };
}
