import type { ToggleDataLocalCache } from './toggleCache';

export const safeSetHas = <T>(set: Set<string>, value: T): boolean => {
    if (set === undefined || set === null) {
        return false;
    }
    return set.has(`${value}`);
};

export const calcNonSimpleToggleEnabledState = (
    dealerId: string | null,
    userId: string | null,
    dealerIds: Set<string>,
    userIds: Set<string>,
    enabled: boolean
): boolean => {
    if (!dealerId && !userId) {
        return false;
    } else if (safeSetHas(dealerIds, dealerId) || safeSetHas(userIds, userId)) {
        return enabled;
    } else {
        return false;
    }
};

export const isToggleEnabled = (
    toggleDataLocalCache: ToggleDataLocalCache,
    toggleName: string,
    dealerId: string | null,
    userId: string | null
): boolean | null => {
    if (!toggleDataLocalCache) {
        return null;
    }
    const toggleData = toggleDataLocalCache[toggleName];
    if (!toggleData) {
        return null;
    }
    const simpleToggle = !toggleData.dealerIds?.size && !toggleData.userIds?.size;
    if (simpleToggle) {
        return toggleData.enabled;
    } else {
        return calcNonSimpleToggleEnabledState(dealerId, userId, toggleData.dealerIds, toggleData.userIds, toggleData.enabled);
    }
};
