const MIDDLEWARE_IDENTIFIER = 'analytics';

let debuggingEnabled = false;

export const fireImmediately = (queuedDataUpdates, lastEventTimestamp) => {
    return queuedDataUpdates.length === 0 && lastEventTimestamp && enoughTimeElapsed(lastEventTimestamp.getLastTimestamp());
};

export const enableDebugging = (val: boolean) => {
    debuggingEnabled = val;
};

export const fireAnalyticsFromEventQueue = (lastEventTimestamp, queuedDataUpdates, dataIsland) => {
    try {
        lastEventTimestamp.update();
        if (queuedDataUpdates.length > 0) {
            dataIsland.update(queuedDataUpdates.shift());
        }
    } catch (err) {
        if (debuggingEnabled) {
            console.error('Failed to fire analytics', err.toString());
        }
    }
};

export const eventTimestampManager = () => {
    let timestamp = undefined;
    return {
        update: (override?) => {
            timestamp = override || Date.now();
        },
        getLastTimestamp: () => {
            return timestamp;
        }
    };
};

export const enoughTimeElapsed = (lastEventTimestamp = 0) => Date.now() - lastEventTimestamp >= 1600;

export const shouldExecute = (action) => {
    return Boolean(action && action.meta && action.meta.middleware && action.meta.middleware[MIDDLEWARE_IDENTIFIER]);
};

export function convertDateToUTC(date) {
    return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
    );
}
