// libraries
import { RestApiCaller } from '@makemydeal/dr-common-utils';

// consts/enums
import { ACTIVITY_STATE_COMPLETED, ACTIVITY_STATE_NOT_STARTED } from './consts/activityStates';

// interfaces/types
import type { Persona, ApplicationName, ActivityName, ActivityState, ActivityStateChange } from './types/dpmClientTypes';
import type { DpmEndpointPayload } from './types/dpmRestApiTypes';

export type DpmClientOptions = {
    appName: ApplicationName;
    persona: Persona;
    dealerUser?: string;
};

export type DpmDealSendOrUpdateProgressResult = {
    errorMessage?: string;
};

export enum DpmClientErrorHandling {
    SwallowExceptions,
    ThrowExceptions
}

export enum DpmDealLoadErrorHanding {
    AllowPendingActivityState,
    DisallowPendingActivityState
}

export class DpmClient {
    public appName: ApplicationName;
    public persona: Persona;
    public dealerUser: string | undefined;
    constructor(public restApiCaller: RestApiCaller, options: DpmClientOptions) {
        if (!restApiCaller) {
            throw new Error('DpmClient constructor requires a restApiCaller instance');
        }
        if (!restApiCaller.isConfigured()) {
            throw new Error(
                `DpmClient constructor requires a restApiCaller instance that has been configured: "${restApiCaller.getConfigValidationMessage()}"`
            );
        }
        if (!options.appName) {
            throw new Error('DpmClient constructor requires that a non-empty appName be provided');
        }
        if (!options.persona) {
            throw new Error('DpmClient constructor requires that a non-empty persona be provided');
        }
        this.appName = options.appName;
        this.persona = options.persona;
        this.dealerUser = options.dealerUser;
        this.activityStateChanges = [];
    }

    public setUrl = (url: string) => {
        if (!url) {
            throw new Error('setUrl requires a non-empty URL to be provided');
        }
        this.url = url;
    };

    public updateDealProgressWithValidation = async (
        name: ActivityName,
        desiredState: ActivityState,
        currentState: ActivityState | undefined,
        errorHandling: DpmClientErrorHandling = DpmClientErrorHandling.ThrowExceptions
    ): Promise<DpmDealSendOrUpdateProgressResult> => {
        const noStateChange = currentState === desiredState;
        const isCurrentCompleted = currentState === ACTIVITY_STATE_COMPLETED;

        if (noStateChange || isCurrentCompleted) return {};

        this.activityNotification?.(`registered activity state change - ${name}, ${desiredState}`);
        this.activityStateChanges.push({
            name,
            state: desiredState
        });
        if (this.canSendImmediately()) {
            const stateChangeText = this.activityStateChanges.length === 1 ? 'change' : 'changes';
            this.activityNotification?.(`sending ${this.activityStateChanges.length} queued activity ${stateChangeText}`);
            const updatedMilestoneData = await this.handleDealSend(this.dealXgId, errorHandling);
            return updatedMilestoneData;
        }
        return {};
    };

    public updateDealProgress = async (
        name: ActivityName,
        state: ActivityState,
        errorHandling: DpmClientErrorHandling = DpmClientErrorHandling.ThrowExceptions
    ): Promise<DpmDealSendOrUpdateProgressResult> => {
        this.activityNotification?.(`registered activity state change - ${name}, ${state}`);
        this.activityStateChanges.push({
            name,
            state
        });
        if (this.canSendImmediately()) {
            const stateChangeText = this.activityStateChanges.length === 1 ? 'change' : 'changes';
            this.activityNotification?.(`sending ${this.activityStateChanges.length} queued activity ${stateChangeText}`);
            const updatedMilestoneData = await this.handleDealSend(this.dealXgId, errorHandling);
            return updatedMilestoneData;
        }
        return {};
    };

    public hasPendingActivityStateChanges = () => this.activityStateChanges.length > 0;

    public canSendImmediately = () => !!this.dealXgId;

    public handleDealSend = async (
        dealXgId: string,
        errorHandling: DpmClientErrorHandling = DpmClientErrorHandling.ThrowExceptions
    ): Promise<DpmDealSendOrUpdateProgressResult> => {
        this.activityNotification?.(`sending deal with Deal XG ID ${dealXgId}`);
        const payload = this.buildDpmPayload().dpm;
        const dpmBffUrl = this.url.replace('{dealXgId}', dealXgId);
        this.dealXgId = dealXgId;
        if (this.hasPendingActivityStateChanges()) {
            const callResult = await this.restApiCaller.remoteProcedureCall(dpmBffUrl, payload, {
                headers: {
                    'Content-Type': 'application/vnd.coxauto.v4+json'
                }
            });
            const response = callResult.response;
            if (callResult.ok) {
                this.activityNotification?.('all queued activity state changes have been sent');
                this.activityStateChanges = [];
                return response;
            } else {
                const errorMessage = "handleDealSend's save dpm call failed: " + callResult.rawError;
                this.activityNotification?.(errorMessage);
                if (errorHandling === DpmClientErrorHandling.ThrowExceptions) {
                    throw new Error(errorMessage);
                }
                return {
                    errorMessage
                };
            }
        }
        return {};
    };

    public handleDealLoad = async (dealXgId: string, errorHandling = DpmDealLoadErrorHanding.DisallowPendingActivityState) => {
        this.activityNotification?.(`loading deal with Deal XG ID ${dealXgId}`);
        this.dealXgId = dealXgId;
        if (this.hasPendingActivityStateChanges() && errorHandling === DpmDealLoadErrorHanding.DisallowPendingActivityState) {
            throw new Error(
                'dpmClient.handleDealLoad was called with pending activity state, you can suppress this if you like, ' +
                    'but it is recommended that you do not load a Deal XG Deal with pending updates!'
            );
        }
    };

    public reset = () => {
        this.activityNotification?.('resetting Deal XG ID and clearing queued activity state changes');
        this.dealXgId = undefined;
        this.activityStateChanges = [];
    };

    public buildDpmPayload = (): DpmEndpointPayload => {
        const result: DpmEndpointPayload = {
            dpm: {
                activityStateChanges: this.activityStateChanges,
                changedBy: {
                    applicationName: this.appName,
                    persona: this.persona
                }
            }
        };
        if (this.dealerUser) {
            result.dpm.changedBy.dealerUser = this.dealerUser;
        }
        return result;
    };

    public setActivityHook = (hook: (activityText: string) => void) => {
        this.activityNotification = hook;
    };

    private url: string;
    private activityStateChanges: ActivityStateChange[];
    private dealXgId: string;
    private activityNotification: (activityText: string) => void;
}
