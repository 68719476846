// NOTE: We're building up a library of date utils so that we can move away from moment (it is way too heavyweight for what we need
//       and it makes unit testing more challenging).

import { parse } from 'path';
import { BirthDate, DateFormatOptions, DateFormatTypes, NonUtcDateFormatTypes } from '../interfaces';

// public consts
export const ONE_MINUTE = 60 * 1000;
export const ONE_HOUR = 60 * 60 * 1000;
export const ONE_DAY = 24 * 60 * 60 * 1000;
export const ONE_YEAR = 365 * ONE_DAY;

// private consts
const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const DAYS_3LETTER = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
const MONTHS_3LETTER = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const cloneDate = (date: Date): Date => {
    if (!date) {
        return null;
    }
    const newDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
        date.getMilliseconds()
    );
    return newDate;
};

export const getStartOfDay = (date: Date): Date => {
    if (!date) {
        return null;
    }
    const newDate = cloneDate(date);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);
    return newDate;
};

/**
 * Accepts date string and converts to a UNIX timestamp
 * @param date date/time string
 */
export const parseISODate = (date: string): number => {
    const isoDateRegEx = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
    if (!date || date.match(isoDateRegEx) === null) {
        return null;
    }
    return Date.parse(date);
};

/**
 * Accepts a date string in MM/DD/YYYY format and returns a date Object
 * @param date a date/time string
 * @returns {Date} - Date object
 */
export const parseMMDDYYYY = (date: string): Date => {
    const dateStringRegEx = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    if (!date || date.match(dateStringRegEx) === null) {
        return null;
    }
    const dateParts = date.split('/');
    const dateMonth = parseInt(dateParts[0], 10);
    const dateDay = parseInt(dateParts[1], 10);
    const dateYear = parseInt(dateParts[2], 10);
    return new Date(dateYear, dateMonth - 1, dateDay, 0, 0, 0, 0);
};

/**
 * Determines the difference between days ignoring the time component.
 *
 * NOTE: This isn't intended to handle big differences between dates, so it throw
 *  an exception if you use it for that.
 * @param day1 earlier date with irrelevent time component (it will be ignored)
 * @param day2 later date with irrelevent time component (it will be ignored)
 * @returns {number} - Number of days
 */
export const getDayDiffIgnoringTime = (day1: Date, day2: Date): number => {
    if (!day1 || !day2) {
        return null;
    }
    const day1start = getStartOfDay(day1);
    const day2start = getStartOfDay(day2);
    const result = Math.round((day2start.getTime() - day1start.getTime()) / ONE_DAY);
    return result;
};

export const formatAsDayOfWeek = (day: Date): string => {
    if (!day) {
        return null;
    }
    const dayOfWeek = DAYS[day.getDay()];
    return dayOfWeek;
};

export const formatAs3LetterDayOfWeek = (day: Date): string => {
    if (!day) {
        return null;
    }
    const dayOfWeek = DAYS_3LETTER[day.getDay()];
    return dayOfWeek;
};

const formatAs2Digits = (val: number): string => {
    return val < 10 ? `0${val}` : `${val}`;
};

export const formatAs2DigitDayOfMonth = (day: Date): string => {
    if (!day) {
        return null;
    }
    const dayOfMonth = day.getDate();
    return formatAs2Digits(dayOfMonth);
};

export const formatAsMonth = (day: Date): string => {
    if (!day) {
        return null;
    }
    const month = day.getMonth();
    return MONTHS[month];
};

export const formatAs3LetterMonth = (day: Date): string => {
    if (!day) {
        return null;
    }
    const month = day.getMonth();
    return MONTHS_3LETTER[month];
};

export const getDaySuffix = (dayOfMonth: number): string => {
    if (!dayOfMonth) {
        return null;
    }
    switch (dayOfMonth) {
        case 1:
        case 21:
        case 31: {
            return 'st';
        }
        case 2:
        case 22: {
            return 'nd';
        }
        case 3:
        case 23: {
            return 'rd';
        }
        default: {
            return 'th';
        }
    }
};

/**
 * Format is equivalent to moment's M/D/YYYY
 * @param {Date} date - Date object
 * @returns {string} - Formatted date
 */
export const formatAsMonthDay = (date: Date): string => {
    if (!date) {
        return null;
    }
    const dayText = date.getDate() + getDaySuffix(date.getDate());
    return `${formatAsMonth(date)} ${dayText}`;
};

/**
 * Format is equivalent to moment's M/D/YYYY
 * @param {Date} date - Date object
 * @returns {string} - Formatted date
 */
export const formatAsMDYYYY = (date: Date): string => {
    if (!date) {
        return null;
    }
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

/**
 * Format is equivalent to moment's MM/DD/YYYY
 * @param {Date} date - Date object
 * @returns {string} - Formatted date
 */
export const formatAsMMDDYYYY = (date: Date): string => {
    if (!date) {
        return null;
    }
    let formattedDate: string;
    if (typeof date === 'string') {
        const dateFromStr = cloneDate(parseMMDDYYYY(date));
        formattedDate = `${formatAs2Digits(dateFromStr.getMonth() + 1)}/${formatAs2Digits(
            dateFromStr.getDate()
        )}/${dateFromStr.getFullYear()}`;
    } else {
        formattedDate = `${formatAs2Digits(date.getMonth() + 1)}/${formatAs2Digits(date.getDate())}/${date.getFullYear()}`;
    }
    return formattedDate;
};

/**
 * Formats a date as YYYYMMDD or YYYY-MM-DD
 * @param {Date} date - Date object
 * @param {bolean} includeHyphens - Boolean indicating whether to include hyphens between date values, default true
 * @returns {string} - Formatted date
 */
export const formatAsYYYYMMDD = (date: Date, includeHyphens = true): string => {
    const day = formatAs2Digits(date.getDate());
    const month = formatAs2Digits(date.getMonth() + 1);
    const year = date.getFullYear();
    const separator = includeHyphens ? '-' : '';
    const formattedDate = [year, month, day].join(separator);
    return formattedDate;
};

/**
 * Format is equivalent to ico date 'h:mma'
 * @param {Date} date - Date object
 * @returns {string} - Formatted date
 */
export const formatAsTime = (date: Date): string => {
    if (!date) {
        return '';
    }
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const isPM = hour >= 12;
    const normalizedHour = hour % 12 || 12;
    const normalizedMinutes = minutes ? (minutes < 10 ? `0${minutes}` : minutes) : '00';
    const dayTime = isPM ? 'pm' : 'am';

    return `${normalizedHour}:${normalizedMinutes}${dayTime}`;
};

/**
 * Format is equivalent to ico date 'Mmmm DD, YYYY at h:mma'
 * @param {Date} date - Date object
 * @returns {string} - Formatted date
 */
export const formatAsIcoValuationDate = (date: string): string => {
    if (!date) {
        return null;
    }
    const parsed = parseISODate(date);
    const thisDate = cloneDate(new Date(parsed));
    const hours = () => {
        let hours = thisDate.getUTCHours();
        if (hours > 12) {
            hours -= 12;
        } else if (hours === 0) {
            hours = 12;
        }
        return hours;
    };
    const ampm = thisDate.getHours() >= 12 ? 'pm' : 'am';
    return (
        `${formatAsMonth(thisDate)} ${formatAs2DigitDayOfMonth(thisDate)}, ${thisDate.getFullYear()}` +
        ` at ${hours()}:${formatAs2Digits(thisDate.getMinutes()) + ampm}`
    );
};

/**
 * Format is equivalent to 'Tuesday, June 25th 2019'
 * @param {Date} date - Date object
 * @returns {string} - Formatted date
 */
export const formatAsDealExpirationDate = (date: Date): string => {
    if (!date) {
        return null;
    }
    return `${formatAsDayOfWeek(date)}, ${formatAsMonthDay(date)} ${date.getFullYear()}`;
};

export const addDaysToDate = (date: Date, days: number): Date => {
    if (!date) {
        return null;
    }
    if (!days) {
        return cloneDate(date);
    }
    return new Date(date.getTime() + ONE_DAY * days);
};

export const addHoursToDate = (date: Date, hours: number): Date => {
    if (!date) {
        return null;
    }
    if (!hours) {
        return cloneDate(date);
    }
    return new Date(date.getTime() + ONE_HOUR * hours);
};

/**
 * Formats date and optionaly time
 * @param {Date} date - Date object
 * @param {DateFormatTypes} format - Optional dateFormats enum
 * @param {DateFormatOptions} options - Optional dateFormatOptions object
 * @returns {string} - Formatted date
 */
export const formatDateTime = (date: Date, format?: DateFormatTypes, options?: DateFormatOptions): string => {
    const formatDate = options?.utc ? getUtcDate(date) : date;

    switch (format) {
        case 'dd':
            return formatAs2DigitDayOfMonth(formatDate);
        case 'Mmm':
            return formatAs3LetterMonth(formatDate);
        case 'time':
            return formatAsTime(formatDate);
        case 'month':
            return formatAsMonth(formatDate);
        case 'mdyyyy':
            return formatAsMDYYYY(formatDate);
        case 'utcDate':
            return formatUtcDateTimeToDateOnly(formatDate.toString(), options?.formatter, options?.hyphens);
        case 'yyyymmdd':
            return formatAsYYYYMMDD(formatDate, options.hyphens);
        case 'mmddyyyy':
            return formatAsMMDDYYYY(formatDate);
        case 'monthDay':
            return formatAsMonthDay(formatDate);
        case 'IcoValuationDate':
            return formatAsIcoValuationDate(formatDate.toISOString());
        case 'DealExpirationDate':
            return formatAsDealExpirationDate(formatDate);
        default:
            return formatAsMDYYYY(formatDate);
    }
};

/**
 * Returns UTC date
 * @param {Date} date - Date object
 * @returns {Date} - UTC date
 */
export const getUtcDate = (date: Date): Date => {
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    const utcDate = new Date(year, month, day);

    return utcDate;
};

/**
 * Formats date and optionaly time
 * @param {string} date -ISO string
 * @param {NonUtcDateFormatTypes} format - Optional dateFormats enum
 * @param {boolean} hyphens - Optional format with hyphens
 * @returns Formatted date
 */
export const formatUtcDateTimeToDateOnly = (strVal: string, format?: NonUtcDateFormatTypes, hyphens?: boolean): string => {
    const errorMessage = 'Invalid UTC Date';
    if (!strVal) {
        return errorMessage;
    }

    // We need to force the date to UTC if the Z is not already present
    let dateString = strVal;
    if (strVal.indexOf('Z') === -1) {
        dateString = `${strVal}Z`;
    }
    const date = new Date(dateString);

    // to avoid recursive call in case TS warning ignored!
    const doFormat = (format as DateFormatTypes) !== 'utcDate' ? format : undefined;

    const formattedDate = formatDateTime(date, doFormat, { hyphens, utc: true });

    if (!formattedDate) {
        return errorMessage;
    }

    return formattedDate;
};

/**
 * Calculate number of days from argument date to today
 * @param {string} isoDate - ISO formatted date
 * @returns {number|undefined} - Number of days
 */
export const getNumberOfDaysStartingFromTargetDate = (isoDate: string): number | undefined => {
    if (!isoDate) {
        return undefined;
    }

    const sourceDate = new Date(isoDate);
    const sourceTime = sourceDate.getTime();

    if (isNaN(sourceTime)) {
        return undefined;
    }

    const currentDate = new Date();
    const currentTime = currentDate.getTime();

    // Offset between current timezone and UTC in minutes (e.g. +300 for Atlanta, Georgia)
    const timezoneOffset = currentDate.getTimezoneOffset() || 0;

    // Calculate the difference in milliseconds
    const differenceMs = currentTime - (sourceTime + timezoneOffset * ONE_MINUTE);

    // Convert back to days and return
    return Math.floor(differenceMs / ONE_DAY);
};

/**
 * Calculate number of days from today to argument date
 * @param {string} isoDate - ISO formatted date
 * @returns {number|undefined} - Number of days
 */
export const getNumberOfDaysToTargetDate = (isoDate: string): number | undefined => {
    if (!isoDate) {
        return undefined;
    }

    const targetDate = new Date(isoDate);
    const targetTime = targetDate.getTime();

    if (isNaN(targetTime)) {
        return undefined;
    }

    const currentDate = new Date();
    const currentTime = currentDate.getTime();

    // Offset between current timezone and UTC in minutes (e.g. +300 for Atlanta, Georgia)
    const tzOffset = currentDate.getTimezoneOffset() || 0;

    // Calculate the difference in milliseconds
    const diffMs = targetTime - (currentTime + tzOffset * ONE_MINUTE);
    const diffDays = diffMs / ONE_DAY;

    return Math.ceil(diffDays);
};

export function isValidDate(date: Date) {
    return !isNaN(Number(date));
}

export function createShopperBirthDateForPayment(birthDate: BirthDate) {
    if (!birthDate) {
        return undefined;
    }
    const birthDay = birthDate.birthDay ?? '01';
    return `${birthDate.birthYear}-${birthDate.birthMonth}-${birthDay}`;
}
