let newrelic: any = null;

export const init = (instance: any) => {
    if (!instance) {
        throw 'NewRelic instance is required!';
    }
    newrelic = instance;
};

export const addCustomAttributes = (obj: any) => {
    if (newrelic.addCustomAttributes) {
        newrelic.addCustomAttributes(obj);
    }
};
