// externals
import { StatusCodes } from 'http-status-codes';

export type RequestOverrides = {
    throwExceptionsForStatuses?: {
        '4xx'?: boolean;
        '5xx'?: boolean;
        allErrors?: boolean;
    };
    headers?: Headers;
};

export type CommonResponse<T> = {
    ok: boolean;
    statusCode?: number;
    headers?: { [name: string]: string };
    message?: string;
    rawError?: any;
    response?: T;
};

export type ResourceResponse<T> = CommonResponse<T>;

export type RpcResponse<T = any> = CommonResponse<T>;

export type Headers = {
    [name: string]: string;
};

export type AdditionalOptions = {
    throwExceptionsForStatuses?: {
        '4xx'?: boolean;
        '5xx'?: boolean;
        allErrors?: boolean;
    };
};

const STANDARD_HEADERS = {
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json'
};

export type ApiCallerHTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

export type ApiCallerOptions<T = any> = {
    method: ApiCallerHTTPMethod;
    url: string;
    headers: Headers;
    data?: T;
};

export type ApiCallResult<T = any> = {
    status: number;
    data: T;
    headers: Headers;
};

export type ApiCaller = {
    call: (options: ApiCallerOptions<any>) => Promise<ApiCallResult>;
    is4xxError: (errMsg: string) => boolean;
    is5xxError: (errMsg: string) => boolean;
};

export type CallRestApiResult<T = any> = {
    ok: boolean;
    statusCode: number;
    response?: T;
    headers?: Headers;
    message?: string;
    rawError?: any;
};

export class RestApiCaller {
    constructor() {}
    private apiCaller: ApiCaller;
    setupApiCaller = (apiCaller: ApiCaller) => {
        this.apiCaller = apiCaller;
        if (!this.isRestApiCallerValid()) {
            throw new Error(
                `restApiCaller.setupApiCaller must be called with a valid apiCaller: "${this.getRestApiCallerValidationMessage()}"`
            );
        }
    };
    reset = () => {
        this.apiCaller = undefined;
    };
    isRestApiCallerValid = () => !this.getRestApiCallerValidationMessage();
    getRestApiCallerValidationMessage = (): string | undefined => {
        if (!this.apiCaller) {
            return 'apiCaller provided is undefined';
        }
        if (!this.apiCaller.call) {
            return 'apiCaller provided does not have a call function';
        }
        return undefined;
    };
    isConfigured = () => !this.getConfigValidationMessage();
    getConfigValidationMessage = (): string | undefined => {
        if (!this.apiCaller) {
            return 'restApiCaller.setupApiCaller needs to be called first';
        }
        return undefined;
    };
    getResource = async <T>(resourceItemUri: string, overrides?: RequestOverrides): Promise<ResourceResponse<T>> => {
        const additionalOptions: AdditionalOptions = this.buildAdditionalOptions(overrides);
        const result = await this.callRestApi(
            {
                method: 'GET',
                url: resourceItemUri,
                headers: this.buildHeaders(overrides?.headers)
            },
            additionalOptions
        );
        return result as unknown as ResourceResponse<T>;
    };

    _httpResource = async <T>(
        resourceCollectionUri: string,
        method: ApiCallerHTTPMethod,
        resource: any,
        overrides?: RequestOverrides
    ): Promise<ResourceResponse<T>> => {
        const additionalOptions: AdditionalOptions = this.buildAdditionalOptions(overrides);
        const result = await this.callRestApi(
            {
                method,
                url: resourceCollectionUri,
                headers: this.buildHeaders(overrides?.headers),
                data: resource
            },
            additionalOptions
        );
        return result as unknown as ResourceResponse<T>;
    };

    /**
     * This uses the HTTP POST verb to create a specified resource.  The URL should follow typical REST conventions for a resource
     * (for example, "/dealers") or a collection (for example, "/dealers")
     * @param resourceItemUri resource URI (see example in description)
     * @param resource the resource to be created
     * @returns typically this should return an updated copy of the resource.
     */
    postResource = async <T = any>(
        resourceItemUri: string,
        resource: any,
        overrides?: RequestOverrides
    ): Promise<ResourceResponse<T>> => {
        return await this._httpResource(resourceItemUri, 'POST', resource, overrides);
    };

    /**
     * This uses the HTTP PUT verb to update a specified resource.  The URL should follow typical REST conventions for a resource
     * (for example, "/dealers/{dealerId}") or a collection (for example, "/dealers")
     * @param resourceItemUri resource URI (see example in description)
     * @param resource the resource to be updated
     * @returns typically this should return an updated copy of the resource.
     */
    putResource = async <T = any>(
        resourceItemUri: string,
        resource: any,
        overrides?: RequestOverrides
    ): Promise<ResourceResponse<T>> => {
        return await this._httpResource(resourceItemUri, 'PUT', resource, overrides);
    };

    /**
     * This uses the HTTP PATCH verb to update a specified resource.  The URL should follow typical REST conventions for a resource
     * (for example, "/dealers/{dealerId}") or a collection (for example, "/dealers")
     * @param resourceItemUri resource URI (see example in description)
     * @param resource the resource to be updated
     * @returns typically this should return an updated copy of the resource.
     */
    patchResource = async <T = any>(
        resourceItemUri: string,
        resource: any,
        overrides?: RequestOverrides
    ): Promise<ResourceResponse<T>> => {
        return await this._httpResource(resourceItemUri, 'PATCH', resource, overrides);
    };

    /**
     * @deprecated Please use putResource instead.
     */
    updateResource = this.putResource;

    /**
     * @deprecated Please use postResource instead.
     */
    addResource = this.postResource;

    /**
     * This uses the HTTP DELETE verb to delete the specified resource.  The URL should follow typical REST conventions for a
     * resource (for example, "/dealers/{dealerId}").
     * @param resourceItemUri resource URI (see example in description)
     * @param resource the resource to be deleted
     */
    deleteResource = async <T>(resourceItemUri: string, overrides?: RequestOverrides) => {
        const additionalOptions: AdditionalOptions = this.buildAdditionalOptions(overrides);
        return await this.callRestApi(
            {
                method: 'DELETE',
                url: resourceItemUri,
                headers: this.buildHeaders(overrides?.headers)
            },
            additionalOptions
        );
    };
    /**
     * This uses the HTTP POST verb to essentially perform a remote procedure call (rather than CRUD resource operations).
     * @param actionEndpoint "rpc" endpoint
     * @param actionArgsAsObject parameters (rather than a resource) provided to action being executed with POST
     * @returns results of "rpc" call.
     *
     * NOTE: The current implementation matches "addResource" but semantics matter, so remoteProcedureCall should be used
     *   when a "REST" API isn't truly restful.  If it is more of an RMM Level 1 API then remoteProcedureCall should be used
     *   to show intent of calling a "function" rather than adding a resource.
     */
    remoteProcedureCall = async <T>(
        actionEndpoint: string,
        actionArgsAsObject: any,
        overrides?: RequestOverrides
    ): Promise<RpcResponse<T>> => {
        const additionalOptions: AdditionalOptions = this.buildAdditionalOptions(overrides);
        const result = await this.callRestApi(
            {
                method: 'POST',
                url: actionEndpoint,
                headers: this.buildHeaders(overrides?.headers),
                data: actionArgsAsObject
            },
            additionalOptions
        );
        return result as unknown as RpcResponse<T>;
    };

    private callRestApi = async (options: ApiCallerOptions, additionalOptions: AdditionalOptions | undefined) => {
        if (!this.isConfigured()) {
            throw new Error(this.getConfigValidationMessage());
        }
        try {
            const response = await this.apiCaller.call(options);

            const result: CallRestApiResult = {
                ok: true,
                statusCode: response?.status,
                response: response?.data,
                headers: response?.headers
            };
            return result;
        } catch (err) {
            const errMsg = `${err}`;
            const throwFor4xxValue = additionalOptions?.throwExceptionsForStatuses?.['4xx'];
            const throwFor5xxValue = additionalOptions?.throwExceptionsForStatuses?.['5xx'];
            /* tslint:disable-next-line */
            const isThrowFor4xxOff = throwFor4xxValue === false;
            /* tslint:disable-next-line */
            const isThrowFor5xxOff = throwFor5xxValue === false;
            const isErrorMsg4xx = this.apiCaller.is4xxError(errMsg);
            const isErrorMsg5xx = this.apiCaller.is5xxError(errMsg);
            let shouldReturnObject: boolean;
            if (additionalOptions?.throwExceptionsForStatuses?.allErrors) {
                if (isThrowFor4xxOff && isErrorMsg4xx) {
                    shouldReturnObject = true;
                } else if (isThrowFor5xxOff && isErrorMsg5xx) {
                    shouldReturnObject = true;
                } else {
                    shouldReturnObject = false;
                }
            } else {
                if (throwFor4xxValue && isErrorMsg4xx) {
                    shouldReturnObject = false;
                } else if (throwFor5xxValue && isErrorMsg5xx) {
                    shouldReturnObject = false;
                } else {
                    shouldReturnObject = true;
                }
            }
            if (shouldReturnObject) {
                return {
                    ok: false,
                    statusCode: err.response?.status || StatusCodes.INTERNAL_SERVER_ERROR,
                    message: err.message,
                    rawError: err,
                    response: err.response?.data
                };
            } else {
                throw err;
            }
        }
    };
    private buildAdditionalOptions = (overrides: RequestOverrides | undefined) => {
        if (
            overrides?.throwExceptionsForStatuses?.['4xx'] ||
            overrides?.throwExceptionsForStatuses?.['5xx'] ||
            overrides?.throwExceptionsForStatuses?.allErrors
        ) {
            return { throwExceptionsForStatuses: overrides.throwExceptionsForStatuses };
        }
        return undefined;
    };
    private buildHeaders = (headers: Headers) => {
        return headers ? { ...STANDARD_HEADERS, ...headers } : STANDARD_HEADERS;
    };
}

const restApiCaller = new RestApiCaller();

export default restApiCaller;
