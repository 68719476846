// consts/enums
import {
    ACTIVITY_DISPLAY_NAME_ACCESSORIES,
    ACTIVITY_DISPLAY_NAME_CREDIT_APPLICATION,
    ACTIVITY_DISPLAY_NAME_DOC_UPLOAD,
    ACTIVITY_DISPLAY_NAME_INCENTIVES,
    ACTIVITY_DISPLAY_NAME_OFFER,
    ACTIVITY_DISPLAY_NAME_TEST_DRIVE,
    ACTIVITY_DISPLAY_NAME_TRADE_IN,
    ACTIVITY_DISPLAY_NAME_VEHICLE_PROTECTION,
    ACTIVITY_DISPLAY_NAME_VEHICLE_RESERVATION
} from './consts/activityDisplayNames';
import {
    ACTIVITY_NAME_ACCESSORIES,
    ACTIVITY_NAME_CREDIT_APPLICATION,
    ACTIVITY_NAME_DOC_UPLOAD,
    ACTIVITY_NAME_INCENTIVES,
    ACTIVITY_NAME_OFFER,
    ACTIVITY_NAME_TEST_DRIVE,
    ACTIVITY_NAME_TRADE_IN,
    ACTIVITY_NAME_VEHICLE_PROTECTION,
    ACTIVITY_NAME_VEHICLE_RESERVATION
} from './consts/activityNames';

// interfaces/types
import type { ActivityName } from './types/dpmClientTypes';

export const activityNameToDisplayName = (activityName: ActivityName): string | null => {
    switch (activityName) {
        case ACTIVITY_NAME_ACCESSORIES:
            return ACTIVITY_DISPLAY_NAME_ACCESSORIES;
        case ACTIVITY_NAME_CREDIT_APPLICATION:
            return ACTIVITY_DISPLAY_NAME_CREDIT_APPLICATION;
        case ACTIVITY_NAME_DOC_UPLOAD:
            return ACTIVITY_DISPLAY_NAME_DOC_UPLOAD;
        case ACTIVITY_NAME_INCENTIVES:
            return ACTIVITY_DISPLAY_NAME_INCENTIVES;
        case ACTIVITY_NAME_OFFER:
            return ACTIVITY_DISPLAY_NAME_OFFER;
        case ACTIVITY_NAME_TEST_DRIVE:
            return ACTIVITY_DISPLAY_NAME_TEST_DRIVE;
        case ACTIVITY_NAME_TRADE_IN:
            return ACTIVITY_DISPLAY_NAME_TRADE_IN;
        case ACTIVITY_NAME_VEHICLE_PROTECTION:
            return ACTIVITY_DISPLAY_NAME_VEHICLE_PROTECTION;
        case ACTIVITY_NAME_VEHICLE_RESERVATION:
            return ACTIVITY_DISPLAY_NAME_VEHICLE_RESERVATION;
        default:
            return null;
    }
};
