import {
    UserEvent,
    BaseDataLayer,
    DataLayerReadyEvent,
    EventInfo,
    EventType,
    EventInfoV3,
    UserEventV3,
    DataLayerReadyEventV3,
    BaseDataLayerV3
} from './types';

/**
 * Builds a user event object
 */
export const buildUserEvent = (eventInput: EventInfo): UserEvent => ({
    event: EventType.USER_EVENT,
    ...eventInput
});

/**
 * Builds a user event object for CDLV3
 */
export const buildUserEventV3 = (eventInput: EventInfoV3): UserEventV3 => ({
    event: eventInput
});

/**
 * Builds a ready event object signifying to GTM that we can now start logging events
 */
export const buildReadyEvent = (eventInput: BaseDataLayer): DataLayerReadyEvent => ({
    event: EventType.DATA_LAYER_READY,
    [EventType.DATA_LAYER_READY]: true,
    ...eventInput
});

/**
 * Builds a ready event object signifying to CDLV3 that we can now start logging events
 */
export const buildReadyEventV3 = (eventInput: BaseDataLayerV3): DataLayerReadyEventV3 => ({
    [EventType.DATA_LAYER_READY]: true,
    ...eventInput
});
