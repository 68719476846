export type SuccessCallback = (toggles: ToggleStatsToggleItem[]) => void;
export type FailureCallback = (err: string) => void;

export type ToggleUseRegistryApiCallback = {
    (toggles: ToggleStatsToggleItem[]): void;
};

export type ToggleUseRegistryCallbackData = {
    apiCallFunction: ToggleUseRegistryApiCallback;
};

export const TOGGLE_REGISTRY_IDLE_TIMEOUT = 1000;

export enum ToggleUseSource {
    Selector,
    Reducer,
    Hook
}

export type ToggleUseRegistryData = {
    count: number;
    sources: Set<ToggleUseSource>;
    isKnownToggle?: boolean;
    lastUsed?: string | undefined;
};

export type ToggleStatsToggleItem = {
    name: string;
    lastUsed: string;
};

export type ToggleStatsApiCallbackFunction = (toggles: ToggleStatsToggleItem[]) => void;

export class ToggleUseRegistry {
    private timeout: number = undefined;
    private collectStats = false;
    private currentDate: string | undefined = undefined;
    private usedToggles: Record<string, ToggleUseRegistryData> = {};
    private apiCallInProgress = false;
    private apiCallFailureCount = 0;
    private togglesForLastApiCall: ToggleStatsToggleItem[] = [];
    private apiCallFunction: ToggleUseRegistryApiCallback | undefined = undefined;
    private timeoutHandle: NodeJS.Timeout | undefined = undefined;
    private getDefaultData = () => ({
        count: 0,
        sources: new Set([])
    });
    /**
     * Use API callback that's been registed to make the necessary API calls to update
     * toggle stats.  If it is unable to make an API call, but it is needed then this
     * function will return true to tell the caller to wait a bit longer and try again.
     */
    private performApiCallsAndContinue = (): boolean => {
        if (!this.collectStats) {
            return false;
        }
        if (this.apiCallInProgress) {
            // if we have one API call in progress we can't assume it will be
            // successful, so we queue another attempt just in case.
            return true;
        } else if (this.apiCallFailureCount < 3) {
            this.togglesForLastApiCall = this.buildToggleStatsForPayload();
            if (this.togglesForLastApiCall.length > 0) {
                if (this.apiCallFunction) {
                    this.apiCallInProgress = true;
                    this.apiCallFunction(this.togglesForLastApiCall);
                }
                // tell caller to wait a bit and check again to see if there's
                // updated data to make a new API call.
                return true;
            }
            // there's no data now, there won't be new data until another registerToggleUse
            // call and that will trigger this timeout loop again.
            return false;
        }
    };
    private clearApiTimeouts = () => {
        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
            this.timeoutHandle = undefined;
        }
    };
    private waitAndMakeDelayedApiCall = () => {
        this.clearApiTimeouts();
        this.timeoutHandle = (setTimeout(() => {
            if (this.performApiCallsAndContinue()) {
                this.waitAndMakeDelayedApiCall();
            }
        }, this.timeout) as unknown) as NodeJS.Timeout;
    };
    public clearApiCallback = () => {
        this.clearApiTimeouts();
    };
    public registerToggleUse = (name: string, source: ToggleUseSource): number => {
        const result = this.getToggleUsageCount(name) + 1;
        const existingData = this.usedToggles[name] || this.getDefaultData();
        this.usedToggles[name] = {
            ...existingData,
            count: result,
            sources: existingData.sources.add(source)
        };
        this.waitAndMakeDelayedApiCall();
        return result;
    };
    public markToggleUseAsPersisted = (name: string) => {
        const existingData = this.usedToggles[name] || this.getDefaultData();
        this.usedToggles[name] = {
            ...existingData,
            lastUsed: this.currentDate
        };
    };
    public getToggleUsageCount = (name: string): number => {
        const existingData = this.usedToggles[name] || this.getDefaultData();
        return existingData.count;
    };
    public isApiCallbackRegistered = (): boolean => {
        return !!this.apiCallFunction;
    };
    public registerApiCallback = (onApiCall: ToggleStatsApiCallbackFunction, timeout = TOGGLE_REGISTRY_IDLE_TIMEOUT): boolean => {
        this.timeout = timeout;
        let result = false;
        if (!this.apiCallFunction) {
            this.apiCallFunction = onApiCall;
            result = true;
        }
        this.apiCallInProgress = false;
        this.apiCallFailureCount = 0;
        return result;
    };
    public unregisterApiCallback = (): ToggleStatsApiCallbackFunction => {
        const result = this.apiCallFunction;
        this.apiCallFunction = undefined;
        return result;
    };
    public onApiCallSuccess = () => {
        this.apiCallInProgress = false;
        this.apiCallFailureCount = 0;
        this.togglesForLastApiCall.forEach((toggle) => {
            this.markToggleUseAsPersisted(toggle.name);
        });
    };
    public onApiCallFailure = (message: string, response: string) => {
        console.warn(`Error occurred making toggle stats API call: "${message}" ("${response}")`);
        this.apiCallInProgress = false;
        this.apiCallFailureCount++;
        if (this.apiCallFailureCount >= 3) {
            console.error('Disabling toggle stats API calls because of 3 call failures in a row');
        }
    };
    public buildToggleStatsForPayload = () => {
        const toggles: ToggleStatsToggleItem[] = [];
        Object.keys(this.usedToggles).forEach((toggleName) => {
            const data = this.usedToggles[toggleName];
            if (data.isKnownToggle && data.count > 0) {
                const currentDateToUse = this.currentDate || '';
                if (currentDateToUse) {
                    const lastUsedToUse = data.lastUsed || '';
                    const lastUsedDateIsOld = lastUsedToUse < currentDateToUse;
                    if (!lastUsedToUse || lastUsedDateIsOld) {
                        toggles.push({
                            name: toggleName,
                            lastUsed: currentDateToUse
                        });
                    }
                }
            }
        });
        return toggles;
    };
    public addKnownToggle = (toggleName: string) => {
        const existingData = this.usedToggles[toggleName] || this.getDefaultData();
        this.usedToggles[toggleName] = {
            ...existingData,
            isKnownToggle: true
        };
    };
    public storeLastUsedForToggle = (toggleName: string, lastUsed: string | undefined) => {
        const existingData = this.usedToggles[toggleName] || this.getDefaultData();
        this.usedToggles[toggleName] = {
            ...existingData,
            lastUsed
        };
    };
    public enableStatsCollection = (currentDate: string | undefined) => {
        this.collectStats = true;
        this.currentDate = currentDate;
    };
    public disableStatsCollection = () => {
        this.collectStats = false;
    };
}

export const toggleUseRegistry = new ToggleUseRegistry();
