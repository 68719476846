import {
    DLInput,
    BaseDataLayer,
    GoogleTagManagerProps,
    CommonApplication,
    CommonUserV2,
    CommonContextV2,
    CommonDataLayerV3Props
} from './types';

/**
 * Initializes a data layer event with all required properties present, even if they are undefined
 */
export const buildInitialDataLayerV1 = (input: DLInput): BaseDataLayer => ({
    dataLayerReady: false,
    common: {
        dataLayerVersion: 1,
        user: {
            bridgeUser: undefined,
            applicationUser: undefined,
            userType: input.userType,
            isInternalUser: input.isUserInternal
        },
        application: {
            businessUnitName: 'Digital Retailing',
            name: input.applicationName,
            version: '1.0',
            environment: input.environment,
            isProduction: input.isProduction
        },
        context: {
            dealershipId: undefined,
            dealershipName: undefined
        }
    }
});

export const buildInitialDataLayerV2 = (input: DLInput): BaseDataLayer => ({
    dataLayerReady: false,
    common: {
        dataLayerVersion: 2,
        user: {
            bridgeUsername: input.bridgeUsername,
            bridgePlatformId: input.bridgePlatformId,
            solutionPrincipalId: input.solutionPrincipalId,
            userType: input.userType,
            isInternalUser: input.isUserInternal
        } as CommonUserV2,
        application: {
            businessUnitName: 'Digital Retailing',
            name: input.applicationName,
            version: '1.0',
            environment: input.environment,
            isProduction: input.isProduction
        } as CommonApplication,
        context: {
            dealershipId: undefined,
            dealershipName: undefined,
            commonOrgId: input.commonOrgId
        } as CommonContextV2
    }
});

/** Initializes GoogleTagManager by adding a script to the page and initializing its data layer array */
export const initDrGtm = ({
    gtmId,
    applicationName,
    userType,
    isUserInternal,
    environment,
    isProduction,
    dataLayerName,
    version = 1,
    commonOrgId,
    bridgeUsername,
    bridgePlatformId,
    solutionPrincipalId
}: GoogleTagManagerProps): void => {
    const windowBinding: any = window;
    windowBinding[dataLayerName] = [
        version === 2
            ? buildInitialDataLayerV2({
                  applicationName,
                  userType,
                  isUserInternal,
                  environment,
                  isProduction,
                  commonOrgId,
                  bridgeUsername,
                  bridgePlatformId,
                  solutionPrincipalId
              })
            : buildInitialDataLayerV1({
                  applicationName,
                  userType,
                  isUserInternal,
                  environment,
                  isProduction
              })
    ];
    windowBinding[dataLayerName].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const firstScript = document.getElementsByTagName('head')[0];
    const newScript = document.createElement('script');
    newScript.async = true;
    newScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}&l=${dataLayerName}`;
    firstScript.parentNode.insertBefore(newScript, firstScript);
};

// map properties from input object to respective keys in the returing object
export const buildInitialDataLayerV3 = (input: CommonDataLayerV3Props): any => ({
    dataLayerReady: false,
    user: {
        bridgePlatformId: input.user.bridgePlatformId,
        bridgeUsername: input.user.bridgeUsername,
        isInternalUser: input.user.isInternalUser,
        type: input.user.type
    },
    application: {
        componentId: input.application.componentId,
        workloadId: input.application.workloadId,
        isProduction: input.application.isProduction,
        name: input.application.name
    },
    client: {
        clientName: input.client.clientName,
        commonOrgId: input.client.commonOrgId,
        isTestDealer: input.client.isTestDealer,
        vinDealerId: input.client.vinDealerId
    },
    deal: {
        dealId: input.deal.dealId,
        dealVersion: input.deal.dealVersion,
        commonConsumerId: input.deal.commonConsumerId,
        globalCustomerId: input.deal.globalCustomerId,
        autoLeadId: input.deal.autoLeadId,
        paymentMethod: input.deal.paymentMethod,
        dealStatus: input.deal.dealStatus,
        offerPrice: input.deal.offerPrice,
        downPayment: input.deal.downPayment,
        lenderId: input.deal.lenderId,
        lenderDecision: input.deal.lenderDecision,
        termLength: input.deal.termLength,
        monthlyPayment: input.deal.monthlyPayment,
        paymentFrequency: input.deal.paymentFrequency,
        sellRate: input.deal.sellRate,
        buyRate: input.deal.buyRate,
        accessoriesValue: input.deal.accessoriesValue,
        accessories: input.deal.accessories,
        tradeValue: input.deal.tradeValue,
        trade: input.deal.trade,
        incentiveType: input.deal.incentiveType,
        incentivesValue: input.deal.incentivesValue,
        incentives: input.deal.incentives,
        vehicleProtectionPlanValue: input.deal.vehicleProtectionPlanValue,
        vehicleProtectionPlan: input.deal.vehicleProtectionPlan,
        residualValue: input.deal.residualValue,
        leaseMileage: input.deal.leaseMileage,
        acquisitionFee: input.deal.acquisitionFee,
        maxResidualMSRP: input.deal.maxResidualMSRP,
        securityDeposit: input.deal.securityDeposit,
        loanToValue: input.deal.loanToValue
    },
    vehicle: {
        vin: input.vehicle.vin,
        make: input.vehicle.make,
        model: input.vehicle.model,
        year: input.vehicle.year,
        cadsVehicleCatalogId: input.vehicle.cadsVehicleCatalogId
    }
});

export const initDrCdlV3 = (input: CommonDataLayerV3Props): void => {
    const windowBinding: any = window;
    windowBinding.coxAutoDataLayer = windowBinding.coxAutoDataLayer || [];

    try {
        const dataLayer = buildInitialDataLayerV3(input);
        windowBinding.coxAutoDataLayer.push(dataLayer);
    } catch (error) {
        console.error('Error initializing CDL V3:', error);
    }
};

export const loadCDLV3Script = (env: string): void => {
    const firstScript = document.getElementsByTagName('head')[0];
    const newScript = document.createElement('script');
    newScript.defer = true;
    newScript.src = `https://assets.${env}.analytics.dealer.com/pixall/cdl.min.js`;
    firstScript.parentNode.insertBefore(newScript, firstScript);
};
