const defaultIncentives = {
    nonConditionals: [],
    conditionals: []
};

export const mapIncentiveIdsToPDIds = (incentives) => {
    const mappings = {
        incentives: {}
    };
    incentives.map((incentive) => {
        mappings.incentives[incentive.id] = incentive.pdId;
    });

    return mappings;
};

export const formatIncentives = (incentives, offerType, identifier) => {
    const formattedIncentives = incentives.map((incentive) => {
        return {
            ...incentive,
            pdId: incentive.id,
            description: incentive.description || 'See the dealer for details',
            id: `${identifier}_${offerType}_${incentive.id}`
        };
    });
    return formattedIncentives;
};

export const mapIncentivesByOfferType = (incentives = defaultIncentives) => {
    const { conditionals, nonConditionals } = incentives;
    const offerIncentives = {
        conditionals: mapAllIncentivesId(conditionals) || [],
        nonConditionals: mapAllIncentivesId(nonConditionals) || []
    };
    return offerIncentives;
};

export const mapAllIncentivesId = (incentives) => {
    const allIds = incentives.map((incentive) => incentive.id);
    return allIds;
};

export const getIncentiveIds = (incentives) => {
    const mappedIncentives = {};
    incentives.map((incentive) => {
        mappedIncentives[incentive.id] = incentive;
    });

    return mappedIncentives;
};

export const createIncentiveIncentiveIdentifier = (conditionType) => {
    let identifier;
    if (typeof conditionType === 'string') {
        identifier = conditionType.toLowerCase().replace('s', '');
    }

    return identifier;
};

export const combineIncentivesByConditionType = (offerIncentives = [], offerType) => {
    const incentives = [];
    Object.keys(offerIncentives).map((conditionType) => {
        const identifier = createIncentiveIncentiveIdentifier(conditionType);
        if (conditionType && identifier) {
            const incentivesByConditions = offerIncentives[conditionType];
            const formattedIncentives = formatIncentives(incentivesByConditions, offerType, identifier);
            incentives.push(...formattedIncentives);
        }
    });

    return incentives;
};

export const flattenAllIncentives = (leaseIncentives, financeIncentives, cashIncentives) => {
    const lease = combineIncentivesByConditionType(leaseIncentives, 'lease');
    const finance = combineIncentivesByConditionType(financeIncentives, 'finance');
    const cash = combineIncentivesByConditionType(cashIncentives, 'cash');

    return [...lease, ...finance, ...cash];
};

export const getFinanceIncentives = (offer) => {
    const finance = (offer && offer.finance) || {};
    const incentives = (finance && finance.incentives) || defaultIncentives;
    return incentives;
};

export const getLeaseIncentives = (offer) => {
    const lease = (offer && offer.lease) || {};
    const incentives = (lease && lease.incentives) || defaultIncentives;
    return incentives;
};

export const getCashIncentives = (offer) => {
    const cash = (offer && offer.cash) || {};
    const incentives = (cash && cash.incentives) || defaultIncentives;
    return incentives;
};

export const mapIncentivesForOffer = (offer) => {
    const financeIncentives = getFinanceIncentives(offer);
    const cashIncentives = getCashIncentives(offer);
    const leaseIncentives = getLeaseIncentives(offer);

    const incentives = {
        allIds: [],
        byId: {},
        byOfferType: {
            finance: mapIncentivesByOfferType(financeIncentives),
            lease: mapIncentivesByOfferType(leaseIncentives),
            cash: mapIncentivesByOfferType(cashIncentives)
        }
    };

    const allIncentives = flattenAllIncentives(leaseIncentives, financeIncentives, cashIncentives);

    incentives.allIds = mapAllIncentivesId(allIncentives);
    incentives.byId = getIncentiveIds(allIncentives);

    const mappings = mapIncentiveIdsToPDIds(allIncentives);
    return { ...incentives, mappings: mappings.incentives };
};
