// interfaces/types
import type { ToggleDataResultDataItem } from '../types/toggleApiResultTypes';

export type ToggleDataLocalCache = {
    [toggleName: string]: ToggleDataLocalCacheItem;
};

export type ToggleDataLocalCacheItem = {
    dealerIds?: Set<string>;
    userIds?: Set<string>;
    enabled: boolean;
    source: string;
    lastUsed?: string;
};

export const stringArrayToSet = (values: string[]): Set<string> => new Set(values || []);

export const buildToggleDataLocalCache = (toggleItems: ToggleDataResultDataItem[]): ToggleDataLocalCache => {
    const result: ToggleDataLocalCache = {};
    toggleItems.forEach((toggleItem) => {
        const dealerIds = stringArrayToSet(toggleItem.dealerIds);
        const userIds = stringArrayToSet(toggleItem.userIds);
        const newItem: ToggleDataLocalCacheItem = {
            dealerIds,
            userIds,
            enabled: toggleItem.enabled,
            source: toggleItem.source
        };
        if (toggleItem.lastUsed) {
            newItem.lastUsed = toggleItem.lastUsed;
        }
        result[toggleItem.name] = newItem;
    });
    return result;
};
