export enum Cludes {
    includes = 'Includes',
    excludes = 'Excludes'
}

export enum IncentiveType {
    customerCash = 'CustomerCash',
    dealerCash = 'DealerCash'
}

export enum DateFormatters {
    'dd',
    'Mmm',
    'time',
    'month',
    'mdyyyy',
    'utcDate',
    'yyyymmdd',
    'mmddyyyy',
    'monthDay',
    'IcoValuationDate',
    'DealExpirationDate'
}
