// interfaces/types
import type { ToggleDataResultDataItem } from '../types/toggleApiResultTypes';

// consts/enums
import { TOGGLE_CACHE_TIMEOUT_SECONDS } from '../constants/defaults';

// utils
import { sleep } from './jsUtils';
import { calcTimeDiff, TimeDiffStatus } from './timeDiffUtils';

export type DetermineStatusResult = {
    dataIsCurrent: boolean;
    noDataYet: boolean;
    newDateLastUpdated: Date;
};

export const determineStatus = (dateLastUpdated: Date): DetermineStatusResult => {
    let newDateLastUpdated = dateLastUpdated;
    let dataIsCurrent = true;
    const now = new Date();
    const timeDiffResult = calcTimeDiff(dateLastUpdated, now);
    const noDataYet = timeDiffResult.status === TimeDiffStatus.Uninitialized;
    if (noDataYet) {
        newDateLastUpdated = now;
        dataIsCurrent = false;
    } else if (timeDiffResult.status !== TimeDiffStatus.Normal) {
        throw new Error(`Unexpected time diff result status value "${timeDiffResult.status}"`);
    }
    // Check to see if time was set back because we need to deal with this- we only check to see if the time is significant
    // (for now we treat half a minute as significant)
    if (timeDiffResult.seconds <= -30) {
        // time travel- but this is because of DST or the admin decided to update the time manually
        newDateLastUpdated = now;
    } else if (timeDiffResult.seconds >= TOGGLE_CACHE_TIMEOUT_SECONDS) {
        newDateLastUpdated = now;
        dataIsCurrent = false;
    }
    return { dataIsCurrent, noDataYet, newDateLastUpdated };
};

export type EnsureDataCurrentResult = {
    isCachedData: boolean;
    dateLastUpdated: Date;
};

export enum ApiCallStatus {
    Waiting,
    Busy
}

export type HostData = {
    apiCallStatus: ApiCallStatus;
    dateLastUpdated: Date;
};

export const determineStatusAndFetchIfStale = async (
    dateLastUpdated: Date,
    apiCallStatus: ApiCallStatus,
    cachedFeatureToggleData: ToggleDataResultDataItem[] | undefined,
    fetchToggleData: () => Promise<void>,
    getHostData: () => HostData
): Promise<EnsureDataCurrentResult> => {
    const { dataIsCurrent, noDataYet, newDateLastUpdated } = determineStatus(dateLastUpdated);
    if (!dataIsCurrent) {
        let dateToUse = newDateLastUpdated;
        if (apiCallStatus !== ApiCallStatus.Busy) {
            await fetchToggleData();
        } else if (noDataYet) {
            // must revert to date last updated because we're waiting for API call to finish
            dateToUse = dateLastUpdated;
            const maxWait = 20000; // wait no longer than 20 seconds - if API call takes this long there's a problem!
            const sleepTime = 200;
            const maxTryCount = Math.ceil(maxWait / sleepTime);
            let tryCount = 0;
            let currentCallStatus: ApiCallStatus = apiCallStatus;
            let errorMessage = '';
            let abort = false;
            while (!abort && currentCallStatus !== ApiCallStatus.Waiting) {
                await sleep(sleepTime);
                const hostData = getHostData();
                if (!hostData) {
                    // silent abort when host dies for some reason
                    abort = true;
                }
                if (!abort) {
                    currentCallStatus = hostData.apiCallStatus;
                    dateToUse = hostData.dateLastUpdated;
                }
                tryCount++;
                if (currentCallStatus !== ApiCallStatus.Waiting && tryCount >= maxTryCount) {
                    abort = true;
                    errorMessage = `Queued feature toggle call took longer than ${maxWait} milliseconds to complete!`;
                }
            }
            if (errorMessage) {
                throw new Error(errorMessage);
            }
        }
        return {
            isCachedData: false,
            dateLastUpdated: dateToUse
        };
    } else if (!cachedFeatureToggleData) {
        throw new Error('Unexpected state encountered: data determined to be current, but it is not cached in object yet.');
    }
    return {
        isCachedData: true,
        dateLastUpdated: newDateLastUpdated
    };
};
