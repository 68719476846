// utils
import * as urlBuilder from './urlBuilder';

// interfaces/types
import { IParams } from '../interfaces';

//#region Exported Interfaces

export interface IExtractOptions {
    removeQueryString?: boolean;
    removeHash?: boolean;
}

//#endregion

//#region Exported Functions

const SCHEME_SEPERATOR = '://';

export const extractScheme = (url: string) => {
    if (url === null || url === undefined) {
        return null;
    }
    const idx = url.indexOf(SCHEME_SEPERATOR);
    return idx >= 0 ? url.substr(0, idx) : null;
};

export const extractSchemeAndDomain = (url: string): string => {
    const scheme = extractScheme(url);
    if (scheme === null) {
        return null;
    }
    const idx = url.indexOf('/', scheme.length + SCHEME_SEPERATOR.length);
    if (idx >= 0) {
        return url.substr(0, idx);
    }
    const queryIdx = url.indexOf('?', scheme.length + SCHEME_SEPERATOR.length);
    return queryIdx >= 0 ? url.substr(0, queryIdx) : url;
};

export const extractQueryString = (url: string): string => {
    if (!url) {
        return url;
    }
    const pathAndQuery = extractPathAndQueryString(url);
    if (!pathAndQuery) {
        return '';
    }
    const idx = pathAndQuery.indexOf('?');
    return idx >= 0 ? pathAndQuery.substr(idx + 1) : '';
};

const extractPathAndQueryString = (url: string) => {
    const schemeAndDomain = extractSchemeAndDomain(url);
    return url && url.substr(schemeAndDomain.length);
};

export const extractPath = (url: string, options?: IExtractOptions): string => {
    if (options && options.removeQueryString) {
        const pathAndQuery = extractPathAndQueryString(url);
        const idx = pathAndQuery.indexOf('?');
        return idx >= 0 ? pathAndQuery.substr(0, idx) : pathAndQuery;
    } else {
        return extractPathAndQueryString(url);
    }
};

export const extractSchemeDomainAndPath = (url: string, options?: IExtractOptions): string => {
    const schemeAndDomain = extractSchemeAndDomain(url);
    const path = extractPath(url, options);
    return path ? urlBuilder.combine(schemeAndDomain, path) : schemeAndDomain;
};

export const parseParams = (url: string): IParams => {
    const result = {};
    const queryString = extractQueryString(url);
    if (queryString) {
        const parts = queryString.split('&');
        parts.forEach((keyValue) => {
            const idx = keyValue.indexOf('=');
            const key = idx >= 0 ? keyValue.substr(0, idx) : keyValue;
            const value = idx >= 0 ? decodeURIComponent(keyValue.substr(idx + 1)) : null;
            result[key] = value;
        });
    }
    return result;
};

//#endregion
