import { IParams } from '../interfaces';
import startsWith from 'lodash.startswith';
import endsWith from 'lodash.endswith';

// Interfaces

export interface IConfigObj {
    protocol: string;
    host: string;
    port?: number;
    base?: string;
}
// Exported Functions

export const canonicalizeBaseUrl = (baseUrl: string) => {
    const nonNullUrl = baseUrl || '';
    return endsWith(nonNullUrl, '/') ? nonNullUrl.substr(0, nonNullUrl.length - 1) : baseUrl;
};

export const canonicalizeRelativeUrl = (relativeUrl: string) => {
    const nonNullUrl = relativeUrl || '';
    return startsWith(nonNullUrl, '/') ? relativeUrl : `/${nonNullUrl}`;
};

export const combine = (baseUrl: string, relativeUrl?: string) => {
    const newBaseUrl = canonicalizeBaseUrl(baseUrl) || '';
    return newBaseUrl + canonicalizeRelativeUrl(relativeUrl);
};

export const canonicalizeProtocol = (protocol: string) => {
    if (protocol) {
        return endsWith(protocol, '://') ? protocol : `${protocol}://`;
    } else {
        return protocol;
    }
};

export const buildHostUrl = (protocol: string, host: string, port?: number) => {
    const hostAndPort = host + (port ? `:${port}` : '');
    return canonicalizeProtocol(protocol) + hostAndPort;
};

export const combineUrlAndQueryString = (url: string, queryString: string) => {
    const simpleUrl = canonicalizeBaseUrl(url);
    return queryString ? `${simpleUrl}?${queryString}` : simpleUrl;
};

export const buildFromConfig = (configObj: IConfigObj, relativeUrl?: string, queryString?: string) => {
    const hostUrl = buildHostUrl(configObj.protocol, configObj.host, configObj.port);
    const baseUrl = combine(hostUrl, configObj.base);
    const url = combine(baseUrl, relativeUrl);
    return combineUrlAndQueryString(url, queryString);
};

export const paramsToQueryString = (params: IParams): string => {
    let result = '';
    for (const key in params) {
        const value = params[key];
        if (result !== '') {
            result += '&';
        }
        if (value === null) {
            result += key;
        } else {
            result += `${key}=${encodeURIComponent(value)}`;
        }
    }
    return result;
};

export const canonicalizeQueryString = (queryString: string) => {
    return startsWith(queryString, '?') ? queryString.substr(1) : queryString;
};

export const canonicalizePath = (path: string) => {
    return endsWith(path, '?') ? path.substr(0, path.length - 1) : path;
};

export const buildWithQueryString = (schemeDomainAndPath: string, queryString: string) => {
    if (!queryString) {
        return schemeDomainAndPath;
    }
    return `${canonicalizePath(schemeDomainAndPath)}?${canonicalizeQueryString(queryString)}`;
};
