// interfaces/types
import type { ToggleDataResultDataItem } from '../types/toggleApiResultTypes';
import type { ToggleDataLocalCache } from './toggleCache';

export type TogglesClientCacheItem = {
    dateLastUpdated: Date | undefined;
    rawToggleData: ToggleDataResultDataItem[] | undefined;
    toggleDataLocalCache: ToggleDataLocalCache | undefined;
};

export type TogglesClientCacheItems = Record<string, TogglesClientCacheItem>;

export const buildTogglesClientCacheKey = (): string => {
    return `source=default`;
};

export class ToggleCacheItems {
    private cacheItems: TogglesClientCacheItems;
    constructor() {
        this.cacheItems = {};
    }
    getCachedFeatureToggleData = (cacheKey: string): ToggleDataResultDataItem[] | undefined => {
        return this.cacheItems[cacheKey]?.rawToggleData;
    };
    getCachedFeatureToggleByNameData = (cacheKey: string): ToggleDataLocalCache | undefined => {
        return this.cacheItems[cacheKey]?.toggleDataLocalCache;
    };
    getCachedDateLastUpdated = (cacheKey: string): Date | undefined => {
        return this.cacheItems[cacheKey]?.dateLastUpdated;
    };
    ensureCacheItemExists = (cacheKey: string): void => {
        if (!this.cacheItems[cacheKey]) {
            this.cacheItems[cacheKey] = {
                dateLastUpdated: undefined,
                rawToggleData: undefined,
                toggleDataLocalCache: undefined
            };
        }
    };
    setCachedFeatureToggleData = (cacheKey: string, featureToggleData: ToggleDataResultDataItem[]): void => {
        this.ensureCacheItemExists(cacheKey);
        this.cacheItems[cacheKey].rawToggleData = featureToggleData;
    };
    setCachedFeatureToggleByNameData = (cacheKey: string, featureToggleDataByName: ToggleDataLocalCache): void => {
        this.ensureCacheItemExists(cacheKey);
        this.cacheItems[cacheKey].toggleDataLocalCache = featureToggleDataByName;
    };
    setCachedDateLastUpdated = (cacheKey: string, dateLastUpdated: Date): void => {
        this.ensureCacheItemExists(cacheKey);
        this.cacheItems[cacheKey].dateLastUpdated = dateLastUpdated;
    };
}
