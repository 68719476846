// utils
import { applyOverrides } from './utils/internalUtils';

export type ToggleOverrides = {
    enableToggles?: string;
    disableToggles?: string;
};

export type ToggleOverridesResult = Record<string, boolean>;

export const getToggleOverrides = (toggleOverrides: ToggleOverrides): ToggleOverridesResult => {
    let result: ToggleOverridesResult = {};

    if (toggleOverrides.enableToggles) {
        result = applyOverrides(result, toggleOverrides.enableToggles, true);
    }

    if (toggleOverrides.disableToggles) {
        result = applyOverrides(result, toggleOverrides.disableToggles, false);
    }

    return result;
};
