import { AnyFSA } from '@makemydeal/dr-platform-shared';
import { Middleware } from 'redux';
import { EventMiddlewareConfig, EventMiddlewareConfigV3 } from './types';

let eventMapKeys: string[];

/**
 * Produces a middleware that handles pushing events to a data layer
 * @typeParam EventMapper - Mapping of redux action type strings to an analytics event producer
 * @param dataLayer - The dataLayer array of analytics events
 * @returns Middleware function
 */
export const buildGTMEventMiddleware = ({
    eventMap,
    dataLayerName,
    enabled = true,
    middlewareEnabledSelector
}: EventMiddlewareConfig): Middleware => ({ getState }) => (next) => (action: AnyFSA) => {
    next(action);
    let isCdlV3Enabled = false;
    try {
        isCdlV3Enabled = middlewareEnabledSelector(getState());
    } catch (e) {
        console.log('Feature Toggles retrieved before App has been bootstraped');
    }
    if (!enabled || isCdlV3Enabled) return;
    if (!eventMapKeys) {
        eventMapKeys = Object.keys(eventMap);
    }
    const shouldProccessAction = eventMapKeys.includes(action.type) && window[dataLayerName];
    if (shouldProccessAction) {
        const mappedEvent = eventMap[action.type];
        const event = typeof mappedEvent === 'function' ? mappedEvent(action, getState()) : mappedEvent;
        window[dataLayerName].push(event);
    }
};

let eventMapKeysV3: string[];

/**
 * Produces a middleware that handles pushing events to a data layer
 * @typeParam EventMapper - Mapping of redux action type strings to an analytics event producer
 * @param coxAutoDataLayer - The coxAutoDataLayer array of analytics events
 * @returns Middleware function
 */
export const buildCDLEventMiddlewareV3 = ({
    eventMap,
    enabled = true,
    middlewareEnabledSelector
}: EventMiddlewareConfigV3): Middleware => ({ getState }) => (next) => (action: AnyFSA) => {
    next(action);
    let isCdlV3Enabled = false;
    try {
        isCdlV3Enabled = middlewareEnabledSelector(getState());
    } catch (e) {
        console.log('Feature Toggles retrieved before App has been bootstraped');
    }

    if (!enabled || !isCdlV3Enabled) return;
    if (!eventMapKeysV3) {
        eventMapKeysV3 = Object.keys(eventMap);
    }
    const shouldProccessAction = eventMapKeysV3.includes(action.type) && window.coxAutoDataLayer;
    if (shouldProccessAction) {
        const mappedEvent = eventMap[action.type];
        const event = typeof mappedEvent === 'function' ? mappedEvent(action, getState()) : mappedEvent;
        window.coxAutoDataLayer.push(event);
    }
};
