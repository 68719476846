import numeral from 'numeral';
import { ILongDisclaimerData, IShortDisclaimerData } from '../interfaces';
import { validateDomain, validateEmail } from './validator';

// Exported Functions

export function roundDollar(dollars: number) {
    return Math.ceil(dollars);
}

export function isValidNumber(validNumber: number) {
    return !(validNumber === null || isNaN(validNumber));
}

export function isValidDollarAmount(dollars: number) {
    return isValidNumber(dollars);
}

export function invalidDollarDisplayValue() {
    return `$${invalidNumberDisplayValue()}`;
}

export function invalidNumberDisplayValue() {
    return '--';
}

export function formatDollars(dollars, defaultString?) {
    if (isValidNumber(dollars)) {
        return numeral(roundDollar(dollars)).format('$0,000');
    }
    return defaultString ? defaultString : invalidDollarDisplayValue();
}

export function formatDollarsAndCents(dollars, defaultString?) {
    if (isValidNumber(dollars)) {
        return numeral(dollars).format('$0,000.00');
    }
    return defaultString ? defaultString : invalidDollarDisplayValue();
}

export function formatMonthlyPayment(dollars, defaultString?) {
    if (isValidNumber(dollars)) {
        const dollarsRounded = Math.round(dollars);
        return numeral(dollarsRounded).format('$0,000');
    }
    return defaultString ? defaultString : invalidDollarDisplayValue();
}

export function formatNumber(numberNeedingFormat: number) {
    return numeral(numberNeedingFormat).format('0,000');
}

export function formatTerm(term: number | string, treatNullOrUndefinedAsInvalid: boolean = true): string {
    if (!term && term !== 0 && term !== '0') {
        return treatNullOrUndefinedAsInvalid ? invalidNumberDisplayValue() : '';
    }
    return `${term}`;
}

export function formatMiles(value: number) {
    const formattedNumber = formatNumber(value);
    return `${formattedNumber} mi`;
}

export function formatCurrencyAmount(amount: number) {
    return isValidDollarAmount(amount) ? formatDollars(amount) : invalidDollarDisplayValue();
}

export function formatAPR(apr: number): string {
    if (isNaN(apr)) {
        return '-';
    }
    return numeral(apr).format('0.00');
}

export function formatDate(date: Date) {
    const finalDay = isNaN(date.getTime()) ? new Date() : date;
    const month = finalDay.getMonth() + 1 > 9 ? finalDay.getMonth() + 1 : `0${finalDay.getMonth() + 1}`;
    const day = finalDay.getDate() > 9 ? finalDay.getDate() : `0${finalDay.getDate()}`;
    return `${month}/${day}/${finalDay.getFullYear()}`;
}

export function formatLongDisclaimer(data: ILongDisclaimerData) {
    const LEASETYPEDISCLAIMER = ' This is a closed lease.';
    const downPayment = formatDollars(data.downPayment);
    const offerPrice = formatDollars(data.offerPrice);
    const totalPaymentAmount = formatDollars(data.totalPaymentAmount);

    let disclaimerText = data.hasOfferPriceChanged
        ? `*Estimated payment based on your asking price of `
        : `*Estimated payment based on vehicle price of `;
    if (data.offerType === 'finance') {
        const monthlyAprCost = formatDollarsAndCents(data.monthlyAprCost);

        disclaimerText += `${offerPrice} at ${data.apr}% APR for ${data.termMonths} months at ${monthlyAprCost}/month, per $1,000 financed, ${downPayment} down for qualified buyers, ${totalPaymentAmount} total of all payments. ${data.cludes} tax, title, registration, and license. Contingent upon dealer’s acceptance of these terms. Excludes leases and balloon contracts. Available at participating dealers by ${data.lender}. Offer may expire at any time without notification. See dealer for details. Subject to credit approval by lender. Rate applies to vehicle shown. Not everyone will qualify.`;
    } else if (data.offerType === 'lease') {
        const annualMiles = formatNumber(data.annualMiles);
        const monthlyPayment = formatMonthlyPayment(data.monthlyPayment);
        const acqLoanFee = formatDollars(data.acqLoanFee);
        const capCostTotal = formatDollars(data.capCostTotal);
        const capCostReduction = formatDollars(data.capCostReduction);
        const securityDeposit = formatDollars(data.securityDeposit);
        const excessMileageCharge = formatDollarsAndCents(data.excessMileageCharge);
        const downPaymentQualifier = data.deductDueAtSigningDealerPref ? 'due at signing' : 'down';
        const cashDownPaymentQualifier = data.deductDueAtSigningDealerPref ? 'Due at signing' : 'Cash down';
        disclaimerText = data.hasOfferPriceChanged
            ? `*Estimated payment based on your asking price of `
            : `*Estimated payment based on advertised price of `;

        disclaimerText += `${offerPrice} at ${annualMiles} miles/year for ${data.termMonths} months with ${downPayment} ${downPaymentQualifier}, ${acqLoanFee} acquisition fee, including the gross capitalized cost of ${capCostTotal}. ${data.cludes} tax, title, registration, and license. Total monthly payments equal ${totalPaymentAmount}. ${cashDownPaymentQualifier} includes ${capCostReduction} capitalized cost reduction, a required security deposit of ${securityDeposit}, and first month’s lease payment of ${monthlyPayment}. ${excessMileageCharge} charge per mile over ${annualMiles} miles per year. For qualified buyers. Contingent upon dealer’s acceptance of these terms. Available at participating dealers by ${data.lender}. Early termination fees may apply. Lessee is responsible for maintenance, insurance, repair, and excess wear/tear at end of lease. Lessee will not be liable for extra charges if realized vehicle value is lower than residual value at end of lease. Lessee may incur penalties and additional charges for delinquent payments. See ${data.dealerName} for complete details on this and other offers.`;
    } else {
        disclaimerText += `${offerPrice}. `;
        if (data.taxesAndFeesTotal) {
            disclaimerText += `${data.cludes} ${formatDollars(data.taxesAndFeesTotal)} in fees`;
            const includesTaxes = data.totalTax ? ` and taxes. ` : `. `;

            disclaimerText += `${includesTaxes}Final transaction may include additional fees/charges and taxes. `;
        }
        disclaimerText += `This is not an offer or sales contract and is contingent upon dealer’s acceptance of these terms. See dealer for terms of purchase.`;
    }

    if (data.programEndDate && data.offerType !== 'cash') {
        const programEndDate = formatDate(new Date(data.programEndDate));
        disclaimerText = `${disclaimerText} Payment Expires: ${programEndDate}.`;
    }

    return data.offerType === 'lease' ? `${disclaimerText}${LEASETYPEDISCLAIMER}` : disclaimerText;
}

export function formatShortDisclaimer(data: IShortDisclaimerData) {
    const downPayment = formatDollars(data.downPayment);
    const offerPrice = formatDollars(data.offerPrice);

    let disclaimerText = '';
    if (data.offerType === 'finance') {
        disclaimerText = data.hasOfferPriceChanged
            ? `*Est. pmt. based on your asking price of `
            : `*Est. pmt. based on vehicle price of `;

        disclaimerText += `${offerPrice} at ${data.apr}% APR for ${data.termMonths} mos w/ ${downPayment} down for qualified buyers. Contingent upon dealer’s acceptance of these terms. Terms may vary.`;
    } else if (data.offerType === 'lease') {
        const annualMiles = formatNumber(data.annualMiles);
        const downPaymentQualifier = data.deductDueAtSigningDealerPref ? 'due at signing' : 'down';
        disclaimerText = data.hasOfferPriceChanged
            ? `*Est. pmt. based on your asking price of `
            : `*Est. pmt. based on adv. price of `;

        disclaimerText += `${offerPrice} at ${annualMiles} mi/yr for ${data.termMonths} mos with ${downPayment} ${downPaymentQualifier} for qualified buyers. Contingent upon dealer’s acceptance of these terms. Terms may vary.`;
    } else {
        disclaimerText = 'Final transaction may include title, registration, license and other additional fees/charges.';
    }

    if (data.excludeTaxesAndFeeOnUnlockedDS) {
        disclaimerText += ' Taxes and dealer fees not included.';
    }

    if (data.programEndDate && data.offerType !== 'cash') {
        const programEndDate = formatDate(new Date(data.programEndDate));
        disclaimerText = `${disclaimerText} Payment Expires: ${programEndDate}.`;
    }

    return disclaimerText;
}

// Redesign Disclaimers
export function formatLongDisclaimerRedesign(data: ILongDisclaimerData) {
    const LEASETYPEDISCLAIMER = ' This is a closed lease.';
    const downPayment = formatDollars(data.downPayment);
    const offerPrice = formatDollars(data.offerPrice);
    const totalPaymentAmount = formatDollars(data.totalPaymentAmount);
    const taxesAndFeesDisclaimer = getTaxesAndFeesDisclaimer(data);

    const isFinance = data.offerType === 'finance' ? `monthly ` : '';

    let disclaimerText = data.hasOfferPriceChanged
        ? `*Estimated ${isFinance}payment based on vehicle price of `
        : `*Estimated ${isFinance}payment based on advertised price of `;

    // For CDD in cases where financialDetails.program.partnerName
    // is blank it makes us unable to pull the offer up in SP so it's
    // defaulted to 'fakedName'
    const isLenderPopulated = data.lender && data.lender !== 'fakedName';

    if (data.offerType === 'finance') {
        const monthlyAprCost = formatDollarsAndCents(data.monthlyAprCost);

        const offerPriceChange = data.hasOfferPriceChanged ? `Subject to acceptance by dealer of your modified terms. ` : '';
        const lenderAvailibilityText = isLenderPopulated
            ? `and is subject to credit approval by ${data.lender}.`
            : 'and is subject to credit approval.';

        disclaimerText += `${offerPrice} at ${data.apr}% APR for ${data.termMonths} months at ${monthlyAprCost}/month, per $1,000 financed, ${downPayment} down for qualified buyers, ${totalPaymentAmount} total of all payments. ${taxesAndFeesDisclaimer} ${offerPriceChange}Excludes leases and balloon contracts. Available at participating dealers ${lenderAvailibilityText} Offer may expire at any time without notification. See dealer for details. Rate applies to vehicle shown. Not everyone will qualify. `;
    } else if (data.offerType === 'lease') {
        const annualMiles = formatNumber(data.annualMiles);
        const monthlyPayment = formatMonthlyPayment(data.monthlyPayment);
        const acqLoanFee = formatDollars(data.acqLoanFee);
        const capCostTotal = formatDollars(data.capCostTotal);
        const capCostReduction = formatDollars(data.capCostReduction);
        const securityDeposit = formatDollars(data.securityDeposit);
        const excessMileageCharge = formatDollarsAndCents(data.excessMileageCharge);
        const isModifiedTerms = data.hasOfferPriceChanged ? ` Subject to acceptance by dealer of your modified terms.` : '';
        const downPaymentQualifier = data.deductDueAtSigningDealerPref ? 'due at signing' : 'down';
        const cashDownPaymentQualifier = data.deductDueAtSigningDealerPref ? 'Due at signing' : 'Cash down';

        const lenderAvailibilityText = isLenderPopulated
            ? `by ${data.lender} at participating dealers.`
            : 'at participating dealers.';

        disclaimerText = data.hasOfferPriceChanged
            ? `*Estimated monthly payment based on vehicle price of `
            : `*Estimated monthly payment based on advertised price of `;

        disclaimerText += `${offerPrice} at ${annualMiles} miles/year for ${data.termMonths} months with ${downPayment} ${downPaymentQualifier}, ${acqLoanFee} acquisition fee, including the gross capitalized cost of ${capCostTotal}. ${taxesAndFeesDisclaimer} Total monthly payments equal ${totalPaymentAmount}. ${cashDownPaymentQualifier} includes ${capCostReduction} capitalized cost reduction, a required security deposit of ${securityDeposit}, and first month’s lease payment of ${monthlyPayment}. ${excessMileageCharge} charge per mile over ${annualMiles} miles per year. For qualified buyers.${isModifiedTerms} Available to approved lessees ${lenderAvailibilityText} Early termination fees may apply. Lessee is responsible for maintenance, insurance, repair, and excess wear/tear at end of lease. Lessee will not be liable for extra charges if realized vehicle value is lower than residual value at end of lease. Lessee may incur penalties and additional charges for delinquent payments. See ${data.dealerName} for complete details on this and other offers. `;
    } else {
        disclaimerText += `${offerPrice}. `;
        if (data.taxesAndFeesTotal) {
            disclaimerText += `Includes ${formatDollars(data.taxesAndFeesTotal)} in fees`;
            const includesTaxes = data.totalTax ? ` and taxes. ` : `. `;

            disclaimerText += `${includesTaxes}Final transaction may include taxes, title, registration, license and other additional fees/charges. `;
        }
        const modifiedTerms = data.hasOfferPriceChanged ? ` and is subject to acceptance by dealer of your modified terms` : '';
        disclaimerText += `This is not an offer or sales contract${modifiedTerms}. See dealer for terms of purchase.`;
    }

    if (data.programEndDate && data.offerType !== 'cash') {
        const programEndDate = formatDate(new Date(data.programEndDate));
        disclaimerText = `${disclaimerText}Available through ${programEndDate}.`;
    }

    return data.offerType === 'lease' ? `${disclaimerText}${LEASETYPEDISCLAIMER}` : disclaimerText;
}

export function formatShortDisclaimerRedesign(data: IShortDisclaimerData) {
    const downPayment = formatDollars(data.downPayment);
    const offerPrice = formatDollars(data.offerPrice);
    const offerPriceChange = data.hasOfferPriceChanged ? `Subject to acceptance by dealer of your modified terms. ` : '';

    let disclaimerText = '';

    if (data.offerType === 'finance') {
        const priceAdvertised = data.hasOfferPriceChanged ? `vehicle` : `adv.`;

        disclaimerText = `*Est. mo. pmt. based on ${priceAdvertised} price of ${offerPrice} at ${data.apr}% APR for ${data.termMonths} mos w/ ${downPayment} down for qualified buyers. ${offerPriceChange}Terms may vary.`;
    } else if (data.offerType === 'lease') {
        const annualMiles = formatNumber(data.annualMiles);
        const downPaymentQualifier = data.deductDueAtSigningDealerPref ? 'due at signing' : 'down';
        disclaimerText = data.hasOfferPriceChanged
            ? `*Est. mo pmt. based on vehicle price of `
            : `*Est. mo pmt. based on adv. price of `;

        disclaimerText += `${offerPrice} at ${annualMiles} mi/yr for ${data.termMonths} mos with ${downPayment} ${downPaymentQualifier} for qualified buyers. ${offerPriceChange}Terms may vary.`;
    } else {
        disclaimerText = 'Final transaction may include title, registration, license and other additional fees/charges.';
    }

    if (data.excludeTaxesAndFeeOnUnlockedDS) {
        disclaimerText += ' Taxes and dealer fees not included.';
    }

    if (data.programEndDate && data.offerType !== 'cash') {
        const programEndDate = formatDate(new Date(data.programEndDate));
        disclaimerText = `${disclaimerText} Available through ${programEndDate}.`;
    }

    return disclaimerText;
}

export function formatShortDisclaimerImprovedWorkflow(data: IShortDisclaimerData) {
    const downPayment = formatDollars(data.downPayment);
    const offerPrice = formatDollars(data.offerPrice);
    const offerPriceChange = data.hasOfferPriceChanged ? `Subject to acceptance by dealer of your modified terms. ` : '';

    let disclaimerText = '';

    if (data.offerType === 'finance') {
        const priceAdvertised = data.hasOfferPriceChanged ? `vehicle` : `adv.`;

        disclaimerText = `*Estimated monthly payment based on ${priceAdvertised} price of ${offerPrice} at ${data.apr}% APR for ${data.termMonths} months with ${downPayment} down for qualified buyers. ${offerPriceChange}Terms may vary.`;
    } else if (data.offerType === 'lease') {
        const annualMiles = formatNumber(data.annualMiles);
        const downPaymentQualifier = data.deductDueAtSigningDealerPref ? 'due at signing' : 'down';
        disclaimerText = data.hasOfferPriceChanged
            ? `*Estimated monthly payment based on vehicle price of `
            : `*Estimated monthly payment based on adv. price of `;

        disclaimerText += `${offerPrice} at ${annualMiles} mi/yr for ${data.termMonths} months with ${downPayment} ${downPaymentQualifier} for qualified buyers. ${offerPriceChange}Terms may vary.`;
    } else {
        disclaimerText = 'Final transaction may include title, registration, license and other additional fees/charges.';
    }

    if (data.programEndDate && data.offerType !== 'cash') {
        const programEndDate = formatDate(new Date(data.programEndDate));
        disclaimerText = `${disclaimerText} Available through ${programEndDate}.`;
    }

    return disclaimerText;
}

export function capitalize(text: string): string {
    if (text) {
        return text.length ? text[0].toLocaleUpperCase() + text.substr(1) : text;
    } else {
        return text;
    }
}

export function maskString(value: string, prefixLength: number = 1): string {
    const length = (value && value.length) || 0;
    if (length <= prefixLength || prefixLength <= 0) {
        return value;
    }
    const prefix = value.substring(0, prefixLength);
    const suffix = '*'.repeat(length - prefixLength);
    const obscured = `${prefix}${suffix}`;
    return obscured;
}

export function maskDomain(domain: string): string {
    const domainIsValid = validateDomain(domain);
    if (!domainIsValid) {
        throw new Error(`Invalid domain ${domain} passed to maskDomain!`);
    }
    const [prefix, suffix] = domain.split('.');
    const maskDomain = `${maskString(prefix)}.${suffix}`;
    return maskDomain;
}

export function maskEmail(email: string): string {
    const emailIsValid = validateEmail(email);
    if (!emailIsValid) {
        throw new Error(`Invalid email ${email} passed to maskEmail!`);
    }
    const [prefix, suffix] = email.split('@');
    const obscuredEmail = `${maskString(prefix, 2)}@${maskDomain(suffix)}`;
    return obscuredEmail;
}

/**
 * This function create the verbiage for the taxes and fees disclaimer
 * Taxes and fees disclaimer uses the payment data to determine the verbiage
 * @return {String} disclaimer verbiage
 * @param {ILongDisclaimerData} terms data
 */
export function getTaxesAndFeesDisclaimer(data: ILongDisclaimerData) {
    if (data.totalTax && data.hasDealerFees) {
        return 'Includes tax, title, registration, and license fees.';
    }

    if (!data.totalTax && !data.hasDealerFees) {
        return 'Excludes tax, title, registration, and license fees.';
    }

    if (data.totalTax && !data.hasDealerFees) {
        return 'Includes tax. Excludes title, registration, and license fees.';
    }

    if (!data.totalTax && data.hasDealerFees) {
        return 'Excludes tax. Includes title, registration, and license fees.';
    }

    return '';
}

// removes all non alpha-numeric characters and replaces spaces with -
export const kebabCase = (str: string) => str.replace(/[^a-z0-9+]+/gi, '-').toLowerCase();

export const roundToTwoDecimal = (numberNeedingRounding: number): number => {
    return Number.parseFloat(numberNeedingRounding.toFixed(2));
};

// creates a masking array for numeric input
export const createNumberMask = (rawValue: string) => {
    const mask = rawValue.split('').map((char) => (/\d/.test(char) ? /\d/ : char));

    const dotIndex = mask.indexOf('.');
    if (dotIndex !== -1) {
        mask.length = Math.min(mask.length, dotIndex + 3);
    }
    return mask;
};
