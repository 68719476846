/**
 * Purpose: To provide utilities that should not be exported out of this library.
 */

type ConvertListToMapResult<T> = {
    [index: string]: T;
};

export const convertListToMap = <T = any>(list: string[] = [], value: T): ConvertListToMapResult<T> => {
    return list.reduce((map: Record<string, T>, obj: string) => {
        map[obj] = value;
        return map;
    }, {});
};

export const convertToggleListStringToMap = (toggleListString: string, value: boolean): ConvertListToMapResult<boolean> => {
    return convertListToMap(toggleListString?.split(','), value);
};

export const applyOverrides = (
    overrides: Record<string, boolean>,
    toggleListString: string,
    value: boolean
): Record<string, boolean> => {
    return {
        ...overrides,
        ...convertToggleListStringToMap(toggleListString, value)
    };
};
