// Exported Functions

const isWhitespaceOnly = (value: string): boolean => {
    const matches = value.match(/[^ ]/g) || [];
    return matches.length === 0;
};

export const validateEmail = (email: string): boolean => {
    const regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return regex.test(email);
};

export const validateDomain = (domain: string): boolean => {
    const regex = /^((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return regex.test(domain);
};

export const validatePhone = (phone: string): boolean => {
    if (!phone) return true;
    const forbiddenCellPhoneTollFreeAreaCodes = ['800', '855', '866'];
    const cleanedPhone = phone.replace(/\D/g, '');
    const areaCode = cleanedPhone.substring(0, 3);
    const regexPattern = /^[2-9][0-9]{2}[2-9][0-9]{2}[0-9]{4}$/;

    if (!regexPattern.test(cleanedPhone)) return false;
    if (forbiddenCellPhoneTollFreeAreaCodes.includes(areaCode)) return false;

    return true;
};

export function validateZipCode(zipcode: string, country: string): boolean {
    const zipValidationRegExModel = {
        US: /^\d{5}$|^\d{5}-\d{4}$/,
        UK: /^(GIR|[A-Z]\d[A-Z\d]??|[A-Z]{2}\d[A-Z\d]??)[ ]??(\d[A-Z]{2})$/
    };
    const countryCode = country || 'US';
    return new RegExp(zipValidationRegExModel[countryCode]).test(zipcode);
}

const matches = (value: string): boolean => (value.match(/[^a-z '-]/gi) || []).length === 0;

export const validateFirstName = (value: string): boolean =>
    !isWhitespaceOnly(value) && matches(value) && value.length > 1 && value.length < 16;

export const validateLastName = (value: string): boolean =>
    !isWhitespaceOnly(value) && matches(value) && value.length > 1 && value.length < 26;

export const validateName = (value: string): boolean => !isWhitespaceOnly(value) && matches(value);

export const validateRequiredField = (value: string): boolean => {
    return value.length > 0;
};
