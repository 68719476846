import { AnyFSA } from '@makemydeal/dr-platform-shared';

export enum EventType {
    USER_EVENT = 'userEvent',
    DATA_LAYER_READY = 'dataLayerReady'
}

export type UserEvent = BaseEvent & EventInfo;
export type UserEventV3 = { event: EventInfoV3 };
export type EventInfo = {
    /** Plain english description of the element with which the user interacted */
    eventElement: string;
    /** The action the user performed, e.g. Clicked */
    eventAction: string;
    /** Plain english description of what occurred after the action took place, e.g. Modal Opened*/
    eventResult: string;
    /** A breadcrumb trail of nested DOM components. These values should be consistent across events such that all user interactions within a given component have the same breadcrumb trail. The top-most item should break down into a reasonable number of application features. E.g. Dashboard > Terms Section > MSRP Input */
    eventLocation: string;
    /** Any quantitative value associated with the action (a count of returned search results, etc). */
    eventValue?: number;
};

export type EventInfoV3 = {
    element: string;
    /** The action the user performed, e.g. Clicked */
    action: string;
    /** Plain english description of what occurred after the action took place, e.g. Modal Opened*/
    intention: string;
    /** A breadcrumb trail of nested DOM components. These values should be consistent across events such that all user interactions within a given component have the same breadcrumb trail. The top-most item should break down into a reasonable number of application features. E.g. Dashboard > Terms Section > MSRP Input */
    location: string[];
    /** Any quantitative value associated with the action (a count of returned search results, etc). */
};

export interface BaseEvent {
    event: EventType;
}
export type EventProducer = (action?: AnyFSA, currentState?: any) => UserEvent | BaseDataLayer;
export type EventOrEventProducer = EventProducer | UserEvent;
export type EventMap = Record<string, EventOrEventProducer>;
export type EventProducerV3 = (action?: AnyFSA, currentState?: any) => UserEventV3 | BaseDataLayer;
export type EventOrEventProducerV3 = EventProducerV3 | UserEventV3;
export type EventMapV3 = Record<string, EventOrEventProducerV3>;
/** The array used to store analytics events, usually accessed via window.dataLayer */
export type DataLayer = any[];
export type EventMiddlewareConfig = {
    eventMap: EventMap;
    dataLayerName: string;
    enabled?: boolean;
    middlewareEnabledSelector: (state: any) => boolean;
};
export type EventMiddlewareConfigV3 = {
    eventMap: EventMapV3;
    enabled?: boolean;
    middlewareEnabledSelector?: (state: any) => boolean;
};
// hardcoded per the analytics docs
export type UserType = 'dealer' | 'shopper';

export type DTVersion2Fields = {
    /** V2: A string which indicates the plaintext Bridge username of the active user. */
    bridgeUsername?: string;
    /** V2: A string which uniquely identifies the currently logged in user across the enterprise. Available as the `sub` value in the OAuth2 access token. */
    bridgePlatformId?: string;
    /** V2: A string which uniquely identifies the currently logged in user in your application's context. */
    solutionPrincipalId?: string;
    /** V2: A string which indicates the dealer context across the enterprise. */
    commonOrgId?: string;
};

export type DLInput = DTVersion2Fields & {
    userType: UserType;
    isUserInternal: boolean;
    applicationName: string;
    environment: string;
    isProduction: boolean;
};

export type CommonContext = {
    /** The DR Admin Dealer ID */
    dealershipId: number | undefined;
    /** The DR Admin Dealer Name */
    dealershipName: string | undefined;
    /** V2: A string which indicates the dealer context across the enterprise. */
    commonOrgId?: string;
};
export type CommonContextV2 = CommonContext & {
    /** V2: A string which indicates the dealer context across the enterprise. */
    commonOrgId?: string;
};

export type CommonUser = {
    /** this is Bridge userCode (from SAML) or undefined */
    bridgeUser?: string | undefined;
    /** Unique identifier for the user, like a name */
    applicationUser?: string | undefined;
    /** Dealership user or shopper */
    userType?: UserType;
    isInternalUser?: boolean;
};

export type UserV3 = {
    type?: UserType;
    isInternalUser?: boolean;
    bridgeUsername?: string;
    emailAddress?: string;
    bridgePlatformId?: string;
};

export type CommonUserV2 = {
    /** Dealership user or shopper */
    userType?: UserType;
    isInternalUser?: boolean;
    /** V2: A string which indicates the plaintext Bridge username of the active user. */
    bridgeUsername?: string;
    /** V2: A string which uniquely identifies the currently logged in user across the enterprise. Available as the `sub` value in the OAuth2 access token. */
    bridgePlatformId?: string;
    /** V2: A string which uniquely identifies the currently logged in user in your application's context. */
    solutionPrincipalId?: string;
};

export type CommonApplication = {
    businessUnitName: string;
    name: string;
    version: string;
    environment: string;
    isProduction: boolean;
};

export type ApplicationV3 = {
    name?: string;
    isProduction?: boolean;
    componentId?: string | number | undefined;
    workloadId?: string | number | undefined;
};

export type BaseDataLayer = {
    /** Common to all Cox Auto GTM implementations */
    common?: {
        dataLayerVersion?: number;
        user?: CommonUser | CommonUserV2;
        application?: CommonApplication;
        context?: CommonContext;
    };
    [key: string]: any; // allows consumers to add more data
};

export type BaseDataLayerV3 = {
    /** Common to all Cox Auto CDL3 (PIXALL) implementations */
    dataLayerVersion?: number;
    user?: UserV3;
    application?: ApplicationV3;
    client: ClientV3;
    [key: string]: any;
};

export type DataLayerReadyEvent = BaseDataLayer & {
    event: EventType;
    dataLayerReady?: boolean;
};
export type DataLayerReadyEventV3 = BaseDataLayerV3 & {
    dataLayerReady?: boolean;
};
export type GoogleTagManagerProps = DTVersion2Fields & {
    /** The Google Tag Manager ID */
    gtmId: string;
    applicationName: string;
    /** The persona of the user e.g. dealer, shopper */
    userType: UserType;
    /** Is the user a Cox team member */
    isUserInternal: true | undefined;
    /** The environment e.g. local, dev, production */
    environment: string;
    isProduction: boolean;
    /** Which dataLayer array GTM will use in case there are multiple */
    dataLayerName: string;
    /** The version of the dataLayer */
    version?: number;
};

type ClientV3 = {
    clientName?: string;
    commonOrgId?: string;
    isTestDealer?: boolean;
    vinDealerId?: number;
};

type VehicleV3 = {
    vin?: string;
    make?: string;
    model?: string;
    year?: number;
    cadsVehicleCatalogId?: string;
};

type DealV3 = {
    dealId?: string;
    dealVersion?: string;
    commonConsumerId?: string;
    globalCustomerId?: string;
    paymentMethod?: string;
    dealStatus?: string;
    offerPrice?: number;
    downPayment?: number;
    lenderDecision?: any;
    termLength?: string;
    monthlyPayment?: number;
    paymentFrequency?: string;
    sellRate?: number;
    buyRate?: number;
    accessoriesValue?: string;
    accessories?: string;
    tradeValue?: number;
    trade?: any;
    incentiveType?: string;
    incentivesValue?: number;
    incentives?: any;
    vehicleProtectionPlanValue?: string;
    vehicleProtectionPlan?: string;
    residualValue?: number;
    leaseMileage?: string;
    acquisitionFee?: number;
    maxResidualMSRP?: number;
    loanToValue?: string;
    securityDeposit?: number;
    autoLeadId?: string;
    lenderId?: string;
};

export type CommonDataLayerV3Props = DTVersion2Fields & {
    applicationName?: string;
    /** The persona of the user e.g. dealer, shopper */
    userType?: UserType;
    /** Is the user a Cox team member */
    isUserInternal?: true | undefined;
    /** The environment e.g. local, dev, production */
    environment?: string;
    isProduction?: boolean;
    /** The version of the dataLayer */
    version?: number;

    application: ApplicationV3;
    user: UserV3;
    vehicle: VehicleV3;
    deal: DealV3;
    client: ClientV3;
};
